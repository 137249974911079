<template>
  <div class="order-creation-modal provider-order-creation">
    <div class="row mobile-wrap-reverse">
      <div
        v-if="!isBookingPage && $store.getters['app/windowSize'] > 767"
        class="col-md-7"
        :class="{ 'disable-view': !phoneUserInfo.id }"
      >
        <service-creation
          ref="menuCreationRef"
          :phone-user-info="editStoreInfo"
          :store-list="storeItemsData"
          @cart-info="userCardInfo"
          @toggle-more="toggleViewMore"
        />
      </div>
      <div :class="isBookingPage ? 'col-md-12' : 'col-md-5'">
        <div class="row">
          <b-col
            v-if="!isProviderAgent"
            cols="7"
            class="mb-1"
          >
            <b-form-group :label="$t('')">
              <b-form-radio-group
                v-model="editStoreInfo.serviceLocation"
                class="d-flex justify-content-start order-type-images"
              >
                <b-form-radio
                  class="custom-radio-right text-uppercase w-common-radio"
                  :value="'OFFSITE'"
                >
                  <img
                    class="icon-lable-radio"
                    :src="require('@/assets/images/app-icons/address/Work.png')"
                  >
                  <span>{{ 'PROVIDER' }}</span>
                </b-form-radio>
                <b-form-radio
                  class="custom-radio-right text-uppercase w-common-radio"
                  :value="'ONSITE'"
                >
                  <img
                    class="icon-lable-radio"
                    :src="require('@/assets/images/app-icons/address/Home.png')"
                  >
                  <span>{{ 'USER' }}</span>
                </b-form-radio>
              </b-form-radio-group>
              <!-- <b-form-select
                v-model="editStoreInfo.serviceLocation"
                :class="{ 'is-invalid': !editStoreInfo.serviceLocation && isDataSubmitted }"
                :options="serviceLocationOptions"
              /> -->
            </b-form-group>
          </b-col>
          <div
            v-if="!isProviderAgent"
            class="col-5 text-right mb-1"
          >
            <button
              class="btn btn-primary p-1"
              @click="createUser"
            >
              {{ $t('Create User') }}
            </button>
          </div>
          <b-col
            v-if="!isProviderAgent"
            :md="12"
            class="phone-number-align mb-1 booking-page-phone"
          >
            <b-input-group>
              <VuePhoneNumberInput
                ref="countryCodeRef"
                v-model="editStoreInfo.phoneNumber"
                required
                :class="{ 'is-invalid': !editStoreInfo.phoneNumber && !editStoreInfo.email && isDataSubmitted }"
                placeholder="+91 0000000000"
                @update="onUpdate"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="getUserByPhone"
                >
                  {{ $t('GO') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent"
            :md="12"
            class="phone-number-align mb-1 booking-page-phone"
          >
            <b-input-group>
              <b-form-input
                v-model="editStoreInfo.email"
                :class="{ 'is-invalid': !editStoreInfo.phoneNumber && !editStoreInfo.email && isDataSubmitted }"
                type="email"
                placeholder="Enter Email"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="searchByEmail"
                >
                  {{ $t('GO') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent"
            md="6"
          >
            <b-form-group :label="$t('First Name')">
              <b-form-input
                v-model="editStoreInfo.firstName"
                :class="{ 'is-invalid': !editStoreInfo.firstName && isDataSubmitted }"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent"
            md="6"
          >
            <b-form-group :label="$t('Last Name')">
              <b-form-input
                v-model="editStoreInfo.lastName"
                :class="{ 'is-invalid': !editStoreInfo.lastName && isDataSubmitted }"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent && editStoreInfo.serviceLocation == 'ONSITE'"
            md="12"
          >
            <b-form-group :label="$t('Customer Location')">
              <b-input-group>
                <b-form-select
                  v-model="editStoreInfo.addressId"
                  :class="{ 'is-invalid': !editStoreInfo.addressId && isDataSubmitted }"
                  required
                  :options="userAddressOptions"
                  @change="setAddressExtraInfo"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    :disabled="!phoneUserInfo.id"
                    @click="createUserAddressModal"
                  >
                    {{ $t('Create Address') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent && editStoreInfo.serviceLocation == 'ONSITE'"
            md="6"
          >
            <b-form-group :label="$t('Flat/Villa/Office No')">
              <b-form-input
                v-model="editStoreInfo.flat_no"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent && editStoreInfo.serviceLocation == 'ONSITE'"
            md="6"
          >
            <b-form-group :label="$t('Building/Villa Name')">
              <b-form-input
                v-model="editStoreInfo.building"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent"
            md="6"
          >
            <b-form-group :label="$t('Country')">
              <b-form-select
                v-model="editStoreInfo.country_id"
                disabled
                :options="countryOptions"
                @change="changeUserCity"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isProviderAgent"
            md="6"
          >
            <b-form-group :label="$t('City')">
              <b-form-select
                v-model="editStoreInfo.city_id"
                disabled
                :options="cityOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="6"
          >
            <b-form-group :label="$t('Payment Mode')">
              <b-form-select
                v-model="editStoreInfo.paymentMode"
                :class="{ 'is-invalid': !editStoreInfo.paymentMode && isDataSubmitted }"
                required
                :options="paymentModeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isBookingPage || isMobile"
            md="12"
            class="mb-1"
          >
            <b-button
              class="w-100"
              variant="primary"
              :disabled="!phoneUserInfo.id"
              @click="createWithMenuModal = true"
            >
              {{ $t('Select Services') }}
            </b-button>
          </b-col>
          <b-col
            v-if="!isProviderAgent"
            md="12"
            class="mb-2"
          >
            <!-- <b-form-group>
              <h5>Schedule</h5>
              <flat-pickr
                v-model="editStoreInfo.schedule"
                class="form-control"
                :config="{ inline: true, enableTime: true, dateFormat: 'Y-m-d H:i' }"
              />
            </b-form-group> -->
            <custom-calender
              ref="calenderRef"
              :hide-submit="true"
              :timing="storeCurrentTimings"
              :all-timings="providerTimings"
              :store-slot-info="providerTimings"
            />
          </b-col>
          <b-col
            v-if="cartInfo && cartInfo.carts && cartInfo.carts.length > 0"
            md="12"
            class="mt-2 table-responsive"
          >
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{{ $t('Service') }}</th>
                  <th>{{ $t('Quantity') }}</th>
                  <th class="text-right">
                    {{ $t('Price') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) of cartInfo.carts"
                  :key="index"
                >
                  <td>{{ index + 1 }}. {{ item.provider_service && item.provider_service.main_service ? (!isEmpty(item.provider_service.title) ? item.provider_service.title : item.provider_service.main_service.service_name) : '' }}</td>
                  <td>{{ item.quantity }} x {{ phoneUserInfo.currency_symbol }} {{ item.service_price }}</td>
                  <td class="text-right">
                    {{ phoneUserInfo.currency_symbol }} {{ item.total_service_price }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Cart Subtotal') }}: {{ phoneUserInfo.currency_symbol }} {{ cartInfo.total_item_price }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Provider Offer') }}: {{ phoneUserInfo.currency_symbol }} {{ !cartInfo.discount ? cartInfo.provider_discount : cartInfo.discount }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Promocode Discount') }}: {{ phoneUserInfo.currency_symbol }} {{ cartInfo.promocode_amount }}
                  </td>
                </tr>
                <tr v-if="editStoreInfo.discount_type && parseTo(cartInfo.total_price_original - cartInfo.total_price, 3) > 0">
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Discount') }}: {{ phoneUserInfo.currency_symbol }} {{ parseTo(cartInfo.total_price_original - cartInfo.total_price, 3) }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    TAX: {{ phoneUserInfo.currency_symbol }} {{ cartInfo.provider_gst_amount ? cartInfo.provider_gst_amount : 0 }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Total Price') }}: {{ phoneUserInfo.currency_symbol }} {{ cartInfo.total_price }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="6"
          >
            <b-form-group :label="$t('Discount')">
              <b-input-group>
                <b-form-select
                  v-model="editStoreInfo.promocode_id"
                  :options="userPromocodesOptions"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    class="px-1"
                    :disabled="!phoneUserInfo.id"
                    @click="applyPromocode"
                  >
                    <feather-icon
                      size="16"
                      icon="CheckIcon"
                    />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="px-1"
                    :disabled="!phoneUserInfo.id"
                    @click="removePromocode"
                  >
                    <feather-icon
                      size="16"
                      icon="XIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="3"
          >
            <b-form-group :label="$t('Percentage')">
              <b-form-input
                v-model="editStoreInfo.percentage"
                type="number"
                @blur="clearCoupon"
                @keyup="calculateTotal('PERCENTAGE')"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="3"
          >
            <b-form-group :label="$t('Fixed')">
              <b-form-input
                v-model="editStoreInfo.fixed"
                type="number"
                @blur="clearCoupon"
                @keyup="calculateTotal('FIXED')"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.serviceLocation == 'OFFSITE' && editStoreInfo.paymentMode === 'CASH'"
            md="4"
          >
            <b-form-group :label="$t('Total Amount')">
              <b-form-input
                :value="totalAmount"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.serviceLocation == 'OFFSITE' && editStoreInfo.paymentMode === 'CASH'"
            md="4"
          >
            <b-form-group :label="$t('Received Amount')">
              <b-form-input
                v-model="editStoreInfo.received_amount"
                type="number"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.serviceLocation == 'OFFSITE' && editStoreInfo.paymentMode === 'CASH'"
            md="4"
          >
            <b-form-group :label="$t('Return Amount')">
              <b-form-input
                :value="returnAmount"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-form-group :label="$t('Comments')">
              <ckeditor
                v-model="editStoreInfo.comments"
                :editor="editor"
              />
            </b-form-group>
          </b-col>
        </div>
        <b-col
          md="12"
          class="text-center my-2 d-flex justify-content-center"
        >
          <button
            v-if="phoneUserInfo.id"
            class="btn btn-primary ml-5"
            @click="createOrder"
          >
            {{ isBookingPage ? 'Book' : 'Create Service' }}
          </button>
          <!-- <button
            v-if="editStoreInfo.without_item == 0"
            class="btn btn-primary ml-5"
            :disabled="!phoneUserInfo.id"
            @click="createWithMenuModal = true"
          >
            Add Services
          </button> -->
        </b-col>
      </div>
    </div>
    <b-modal
      v-model="createUserAddressModalShow"
      :title="$t('Address Details')"
      :ok-title="$t('Save')"
      :no-close-on-backdrop="true"
      hide-footer
      ok-only
      size="lg"
      @ok="createUserAddress"
    >
      <address-modal
        ref="addressModal"
        @address-selected="setPlace"
        @set-address="setAdress"
        @save-details="
          () => {
            createUserAddressModalShow = false
            createUserAddress()
          }
        "
      />
    </b-modal>
    <b-modal
      id="serviceCreationModal"
      v-model="createWithMenuModal"
      :no-close-on-backdrop="true"
      :title="$t('Choose from Service')"
      :ok-title="$t('Save')"
      ok-only
      size="xl"
      @hidden="createWithMenuModal = false"
    >
      <cart-dropdown
        :is-shop-or-provider="true"
        :is-provider="true"
        :order-payload="cartInfo"
        class="store-cart-option-button text-right"
        @value-changed="valueChanged"
      />
      <service-creation
        ref="menuCreationRef"
        :phone-user-info="editStoreInfo"
        :store-list="storeItemsData"
        @cart-info="userCardInfo"
        @toggle-more="toggleViewMore"
      />
    </b-modal>
  </div>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormSelect, BModal, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import moment from 'moment'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import AddressModal from '@/views/users/profile/AddressModal.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '@/views/users/useUser'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import eventBus from '@/libs/event-bus'
import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
import { useProviderUi } from '../useProvider'
import ServiceCreation from './ServiceCreation.vue'
import CustomCalender from '../../users/shared/CustomCalender.vue'
import { isEmpty } from '@/utilities'

export default {
  components: {
    BCol,
    VuePhoneNumberInput,
    BFormGroup,
    BFormInput,
    BModal,
    BFormRadioGroup,
    BFormRadio,
    CustomCalender,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    AddressModal,
    ServiceCreation,
    CartDropdown,
  },
  props: {
    isBookingPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDataSubmitted: false,
      createWithMenuModal: false,
      editStoreInfo: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        country_id: '',
        city_id: '',
        flat: '',
        flatName: '',
        serviceLocation: '',
        paymentMode: '',
        addressId: '',
        comments: '',
        schedule: '',
        without_item: 0,
      },
      cartInfo: {},
      promocodesList: [],
      markers: null,
      center: { lat: 45.508, lng: -73.587 },
      addressTypeInfo: [
        {
          label: 'Home',
          value: 'Home',
        },
        {
          label: 'Work',
          value: 'Work',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
      editor: ClassicEditor,
      phoneNumberPayload: {},
      countryOptions: [],
      cityOptions: [],
      countryPayload: [],
      phoneUserInfo: {},
      shouldCreateUser: 'order',
      userAddressInfo: {},
      createUserAddressModalShow: false,
      storeItemsData: [],
      bookedTimeSlotData: {},
      providerTimings: [],
      storeCurrentTimings: null,
    }
  },
  computed: {
    totalAmount() {
      if (this.cartInfo && this.cartInfo.total_price) {
        return this.cartInfo.total_price
      }
      return 0
    },
    returnAmount() {
      if (this.totalAmount && this.editStoreInfo.received_amount) {
        return this.editStoreInfo.received_amount > this.totalAmount ? this.parseTo(this.editStoreInfo.received_amount - this.totalAmount, 3) : 0
      }
      return 0
    },
    isMobile() {
      return this.$store.getters['app/windowSize'] <= 767
    },
    serviceLocationOptions() {
      return [
        {
          text: 'Select Service Location',
          value: '',
        },
        {
          text: 'At provider location',
          value: 'OFFSITE',
        },
        {
          text: 'At user location',
          value: 'ONSITE',
        },
      ]
    },
    paymentModeOptions() {
      return [
        {
          text: 'Select Payment Mode',
          value: '',
        },
        {
          text: 'CASH',
          value: 'CASH',
        },
        {
          text: 'CARD',
          value: 'CARD',
        },
      ]
    },
    agentInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isProviderAgent() {
      return this.agentInfo.parent_type === 'PROVIDER'
    },
    itemMode() {
      return [
        {
          text: 'Without Item',
          value: 1,
        },
        {
          text: 'With Item',
          value: 0,
        },
      ]
    },
    userPromocodesOptions() {
      return this.promocodesList.map(x => ({
        text: [x.promo_code, 'as', x.promo_description].filter(y => y).join(' '),
        value: x.id,
      }))
    },
    userAddressOptions() {
      if (!this.phoneUserInfo.address) return []
      return this.phoneUserInfo.address.map(x => ({
        text: [x.map_address, x.flat_no, x.building, x.street, x.zipcode].filter(y => y).join(', '),
        value: x.id,
      }))
    },
    storeTimings() {
      if (!this.providerTimings) return null
      const currentDay = moment().format('ddd').toUpperCase()
      return this.providerTimings.find(x => x.store_day === currentDay)
    },
  },
  mounted() {
    const editCartUserExist = this.$store.state['app-ecommerce'].serviceRequestProvider
    if (editCartUserExist && editCartUserExist.user) {
      this.editStoreInfo = {
        phoneNumber: editCartUserExist.user.mobile,
      }
      this.getUserByPhone()
    }
    this.getCountries()
    this.getStoreItems()
    this.getProviderTimings()
    const storeCountryCode = window.SuperApp.getters.userInfo().country_code
    if (storeCountryCode) {
      window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, storeCountryCode, '')
    }
  },
  methods: {
    isEmpty,
    removePromocode() {
      this.editStoreInfo.promocode_id = ''
      this.applyPromocode()
    },
    applyPromocode(clearExtra = 'YES') {
      const payload = []
      if (this.editStoreInfo.promocode_id) {
        payload.push(`promocode_id=${this.editStoreInfo.promocode_id}`)
      }
      if (clearExtra === 'YES') {
        if (this.editStoreInfo.percentage) {
          this.editStoreInfo.percentage = ''
          this.editStoreInfo.discount_type = ''
          this.calculateTotal('PERCENTAGE')
        }
        if (this.editStoreInfo.fixed) {
          this.editStoreInfo.fixed = ''
          this.editStoreInfo.discount_type = ''
          this.calculateTotal('FIXED')
        }
      }
      this.$refs.menuCreationRef.getCartList(`&${payload.join('&')}`)
    },
    toggleViewMore(product) {
      const findProduct = this.storeItemsData.provider_service.find(x => x.id === product.id)
      if (findProduct) {
        findProduct.showFull = !findProduct.showFull
      }
      this.$forceUpdate()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    valueChanged(payload) {
      this.$refs.menuCreationRef.addItemToCart(payload)
    },
    parseTo(value, to) {
      return parseFloat(parseFloat(value).toFixed(to))
    },
    clearCoupon() {
      if (this.editStoreInfo.percentage || this.editStoreInfo.fixed) {
        if (this.editStoreInfo.promocode_id) {
          this.editStoreInfo.promocode_id = ''
          this.applyPromocode('NO')
        }
      }
    },
    async calculateTotal(type) {
      if (window.event && (window.event.key || window.event.code) === 'Tab') {
        return
      }
      if(typeof this.editStoreInfo.promocode_id !== typeof undefined && this.editStoreInfo.promocode_id != '' && this.editStoreInfo.promocode_id > 0){
        await this.clearCoupon()
      }


      let percentage = 0, fixed = 0, discountAmt = 0;
      const allowTypes = ['PERCENTAGE', 'FIXED'];
      this.cartInfo.total_price = window.SuperApp.actions.cloneDeep(this.cartInfo.total_price_original)
      if(!allowTypes.some(at => at == type)){
        this.editStoreInfo.discount_type = ''
        return false
      }
      if(type == 'PERCENTAGE'){
        this.editStoreInfo.fixed = ''
        if(typeof this.editStoreInfo.percentage === typeof undefined || this.editStoreInfo.percentage == '' || this.editStoreInfo.percentage <= 0){
          return false
        }
        percentage = parseFloat(this.editStoreInfo.percentage)
      }
      if(type == 'FIXED'){
        this.editStoreInfo.percentage = ''
        if(typeof this.editStoreInfo.fixed === typeof undefined || this.editStoreInfo.fixed == '' || this.editStoreInfo.fixed <= 0){
          return false
        }
        fixed = parseFloat(this.editStoreInfo.fixed)
      }

      if (this.cartInfo && this.cartInfo.total_item_price != null) {
        const subTotal = this.cartInfo.total_item_price != null ? parseFloat(this.cartInfo.total_item_price) : 0
        const shopDiscount = this.cartInfo.provider_discount != null ? parseFloat(this.cartInfo.provider_discount) : 0
        const finalPriceToCalculate = subTotal - shopDiscount
        this.editStoreInfo.discount_type = type
        if (type === 'PERCENTAGE') {
          discountAmt = this.parseTo((finalPriceToCalculate * percentage) / 100, 3)
        } else if (type === 'FIXED') {
          discountAmt = this.parseTo(fixed, 3)
        }
        if(discountAmt > 0){
          this.cartInfo.total_price = this.parseTo((this.cartInfo.total_price - discountAmt), 3)
        }
      }
    },
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getProviderTimings() {
      const { getProviderTimings } = useUserUi()
      let { slug } = window.SuperApp.getters.userInfo()
      if (this.$store.state['app-ecommerce'].serviceRequestProvider && this.$store.state['app-ecommerce'].serviceRequestProvider.provider) {
        slug = this.$store.state['app-ecommerce'].serviceRequestProvider.provider.slug
      }
      getProviderTimings(`?provider_id=${slug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    resetForm() {
      this.editStoreInfo = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        country_id: '',
        city_id: '',
        flat: '',
        flatName: '',
        serviceLocation: '',
        paymentMode: '',
        addressId: '',
        comments: '',
        schedule: '',
        without_item: 0,
      }
    },
    bookTimeSlot() {
      const formData = new FormData()
      formData.append('day', moment(this.editStoreInfo.schedule).format('DD'))
      formData.append('month', moment(this.editStoreInfo.schedule).format('MM'))
      formData.append('provider_id', window.SuperApp.getters.userInfo().slug)
      formData.append('time', moment(this.editStoreInfo.schedule).format('HH:MM'))
      formData.append('type', this.editStoreInfo.serviceLocation)
      formData.append('user_id', this.phoneUserInfo.id)
      formData.append('year', moment(this.editStoreInfo.schedule).format('YYYY'))
      const { bookServiceTimeslots } = useUserUi()
      showLoader()
      bookServiceTimeslots(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.bookedTimeSlotData = data.responseData
            this.createOrder()
          } else {
            showDangerNotification(this, data.message)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setCountryCity() {
      const storeInfo = window.SuperApp.getters.userInfo()
      this.editStoreInfo.country_id = storeInfo.country_id
      this.editStoreInfo.city_id = ''
      if (this.editStoreInfo.country_id) {
        this.changeUserCity()
        this.editStoreInfo.city_id = storeInfo.city_id
      }
    },
    setAddressExtraInfo() {
      if (this.phoneUserInfo.address) {
        // eslint-disable-next-line eqeqeq
        const isAddressExist = this.phoneUserInfo.address.find(x => x.id == this.editStoreInfo.addressId)
        if (isAddressExist) {
          this.editStoreInfo.building = isAddressExist.building
          this.editStoreInfo.flat_no = isAddressExist.flat_no
        }
      }
    },
    userCardInfo(payload) {
      this.cartInfo = payload
      this.calculateTotal(this.editStoreInfo.discount_type)
    },
    getStoreItems() {
      const { providerServices, providerServicesForAgent } = useProviderUi()
      const callType = window.SuperApp.getters.isAgent() ? providerServicesForAgent : providerServices
      callType()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const storeItemsData = data.responseData
            storeItemsData.provider_service.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.showFull = false
              if (!item.main_service) {
                // eslint-disable-next-line no-param-reassign
                item.main_service = {}
              }
            })
            const storeType = window.SuperApp.getters.userInfo()
            storeItemsData.currency_symbol = storeType.currency_symbol
            storeItemsData.store_name = storeType.store_name
            this.storeItemsData = storeItemsData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setPlace(place) {
      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.userAddressInfo.map_address = place.formatted_address
        this.userAddressInfo.latitude = marker.lat
        this.userAddressInfo.longitude = marker.lng
        this.userAddressInfo.building = place.name
        this.markers = {
          position: marker,
        }
        this.center = marker
      }
    },
    setAdress(place) {
      this.userAddressInfo = JSON.parse(JSON.stringify(place))
    },
    createUserAddressModal() {
      this.createUserAddressModalShow = true
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
    },
    createOrder() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.isDataSubmitted = false
        if (this.$store.state['app-ecommerce'].serviceRequestProvider) {
          this.$emit('save-updated-service', this.$store.state['app-ecommerce'].serviceRequestProvider)
          return
        }
        const formData = new FormData()
        if (this.$refs.calenderRef) {
          this.editStoreInfo.schedule = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
        }
        formData.append('country_id', this.editStoreInfo.country_id)
        formData.append('user_id', this.phoneUserInfo.id)
        formData.append('user_address_id', this.editStoreInfo.addressId)
        formData.append('without_item', this.isBookingPage ? 1 : this.editStoreInfo.without_item)
        formData.append('description', this.editStoreInfo.comments)
        formData.append('order_type', this.editStoreInfo.serviceLocation)
        formData.append('city_id', this.editStoreInfo.city_id)
        if (this.bookedTimeSlotData && this.bookedTimeSlotData.id) formData.append('timeslot_id', this.bookedTimeSlotData.id)
        if (!this.isBookingPage) formData.append('payment_mode', this.editStoreInfo.paymentMode)
        formData.append('delivery_date', moment(this.editStoreInfo.schedule).format('YYYY-MM-DD HH:mm:ss A'))
        formData.append('year', moment(this.editStoreInfo.schedule).format('YYYY'))
        formData.append('month', moment(this.editStoreInfo.schedule).format('MM'))
        formData.append('day', moment(this.editStoreInfo.schedule).format('DD'))
        formData.append('time', moment(this.editStoreInfo.schedule).format('HH:mm'))
        formData.append('book', this.isBookingPage ? 1 : 0)
        formData.append('wallet', '')
        formData.append('onsite', this.editStoreInfo.serviceLocation === 'ONSITE' ? 1 : 0)
        if (this.editStoreInfo.discount_type) {
          formData.append('discount_type', this.editStoreInfo.discount_type)
          formData.append('discount', this.editStoreInfo.discount_type === 'PERCENTAGE' ? this.editStoreInfo.percentage : this.editStoreInfo.fixed)
        }
        if (this.editStoreInfo.promocode_id) {
          formData.append('promocode_id', this.editStoreInfo.promocode_id)
        }
        const { createManualOrder } = useProviderUi()

        showLoader()
        createManualOrder(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              showSuccessNotification(this, 'Service booked successfully!')
              window.SuperApp.actions.newOrder()
              if (this.isBookingPage) {
                eventBus.$emit('user-sync-app', {
                  serviceBookingCreated: true,
                  userId: this.phoneUserInfo.id,
                  providerName: `${window.SuperApp.getters.userInfo().first_name} ${window.SuperApp.getters.userInfo().last_name}`,
                  invoiceId: data.responseData.booking_id,
                })
              }
              this.$emit('create-order', data.responseData)
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      }, 100)
    },
    getCountries() {
      const { countryList } = useUserUi()

      countryList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.countryPayload = data.responseData
            const country = data.responseData.map(x => ({
              text: x.country_name,
              value: x.id,
            }))
            this.countryOptions.push({
              text: 'Select Country',
              value: '',
            })
            country.forEach(element => {
              this.countryOptions.push({
                text: element.text,
                value: element.value,
              })
            })
            this.setCountryCity()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    changeUserCity() {
      this.cityOptions = []
      const { cityByCountry } = useUserUi()
      showLoader()
      cityByCountry(this.editStoreInfo.country_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.cityOptions = data.responseData
            this.cityOptions.unshift({
              text: 'Select City',
              value: '',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    createUser() {
      this.isDataSubmitted = true
      this.$nextTick(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.isDataSubmitted = false
        const userInfo = {
          phone: this.getPhoneNumber(),
          email: this.editStoreInfo.email,
          first_name: this.editStoreInfo.firstName,
          last_name: this.editStoreInfo.lastName,
          country_code: this.phoneNumberPayload.countryCallingCode,
          country_id: this.editStoreInfo.country_id,
          city_id: this.editStoreInfo.city_id,
        }
        showLoader()
        const { createUser } = useProviderUi()

        createUser(userInfo)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.phoneUserInfo = data.responseData
              this.shouldCreateUser = 'order'
              showSuccessNotification(this, 'User created successfully!')
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
    createUserAddress() {
      const address = this.userAddressInfo
      const { createUserAddress } = useProviderUi()
      const formData = new FormData()
      formData.append('map_address', address.map_address)
      formData.append('latitude', address.latitude)
      formData.append('longitude', address.longitude)
      formData.append('flat_no', address.flat_no)
      formData.append('street', address.street)
      formData.append('address_type', address.address_type)
      formData.append('building', address.building)
      formData.append('user_id', this.phoneUserInfo.id)
      formData.append('zipcode', address.zipcode)
      showLoader()
      createUserAddress(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.getUserByPhone()
            showSuccessNotification(this, data.message)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getPhoneNumber() {
      let phoneNo = this.editStoreInfo.phoneNumber
      if (phoneNo) {
        phoneNo = phoneNo.replace(/[^a-zA-Z0-9]/g, '')
      }
      return phoneNo
    },
    getUserByPhone() {
      const { searchByPhoneNumber, searchByPhoneNumberForAgent } = useProviderUi()
      const callType = this.isProviderAgent ? searchByPhoneNumberForAgent : searchByPhoneNumber
      showLoader()
      callType(this.getPhoneNumber())
        .then(({ data }) => {
          this.processDetails(data)
          hideLoader()
        })
        .catch(error => {
          this.phoneUserInfo = {}
          this.shouldCreateUser = 'user'
          showErrorNotification(this, error)
        })
    },
    searchByEmail() {
      const { searchByEmail, searchByEmailForAgent } = useProviderUi()
      const callType = this.isProviderAgent ? searchByEmailForAgent : searchByEmail
      showLoader()
      callType(this.editStoreInfo.email)
        .then(({ data }) => {
          this.processDetails(data)
          hideLoader()
        })
        .catch(error => {
          this.phoneUserInfo = {}
          this.shouldCreateUser = 'user'
          showErrorNotification(this, error)
        })
    },
    providerPromocodes() {
      const { providerPromocodes } = useProviderUi()

      providerPromocodes(`?user_id=${this.phoneUserInfo.id}&provider_id=${window.SuperApp.getters.userInfo().id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.promocodesList = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    processDetails(data) {
      if (data.statusCode === '200') {
        this.phoneUserInfo = data.responseData
        this.editStoreInfo.id = this.phoneUserInfo.id
        this.editStoreInfo.firstName = this.phoneUserInfo.first_name
        this.editStoreInfo.lastName = this.phoneUserInfo.last_name
        this.editStoreInfo.email = this.phoneUserInfo.email
        this.editStoreInfo.country_id = this.phoneUserInfo.country_id
        this.editStoreInfo.city_id = this.phoneUserInfo.city_id
        this.editStoreInfo.without_item = 0
        if (this.phoneUserInfo.address && this.phoneUserInfo.address[0]) {
          this.editStoreInfo.flat = this.phoneUserInfo.address[0].map_address
          this.editStoreInfo.flatName = this.phoneUserInfo.address[0].building
          this.editStoreInfo.addressId = this.phoneUserInfo.address[0].id
          this.setAddressExtraInfo()
        }
        this.setCountryCity()
        if (this.$refs.menuCreationRef) {
          this.$nextTick(() => {
            if (this.phoneUserInfo.country_code) {
              window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, this.phoneUserInfo.country_code, this.phoneUserInfo.mobile)
            }
            this.$refs.menuCreationRef.getCartList()
          })
        }
        this.providerPromocodes()
        const editCartUserExist = this.$store.state['app-ecommerce'].serviceRequestProvider
        if (editCartUserExist && editCartUserExist.requestDetail) {
          this.editStoreInfo.serviceLocation = editCartUserExist.requestDetail.onsite === 1 ? 'ONSITE' : 'OFFSITE'
          const isaddressExist = this.userAddressOptions.find(x => x.text === editCartUserExist.requestDetail.s_address)
          if (isaddressExist) {
            this.editStoreInfo.addressId = isaddressExist.value
            this.setAddressExtraInfo()
          }
          setTimeout(() => {
            if (editCartUserExist.requestDetail.user) {
              window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, editCartUserExist.requestDetail.user.country_code, editCartUserExist.requestDetail.user.mobile)
            }
          }, 250)
        }
      } else {
        this.phoneUserInfo = {}
        this.shouldCreateUser = 'user'
      }
    },
  },
}
</script>

<style lang="scss">
.phone-number-align {
  margin: auto 0;
  margin-top: 0;

  .input-group {
    flex-wrap: nowrap;
  }
}

.order-creation-modal .max-auto,
#menuCreationModal .modal-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal .ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.order-creation-modal {
  .ecommerce-card .item-img img {
    height: 150px;
    object-fit: cover;
  }

  .ecommerce-application .grid-view .ecommerce-card .item-img {
    min-height: 10rem;
  }
}

.order-creation-modal .disable-view {
  opacity: 0.6;
  pointer-events: none;
}

.order-creation-modal .order-type-images {
  img {
    width: 20px;
  }

  .w-common-radio {
    width: 115px;
  }

  .custom-control-label {
    display: flex;
    align-items: center;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    transform: translateY(-50%);
    top: 50%;
  }

  .custom-radio.custom-control {
    border: 1px solid #d8d6de;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 3px;
  }

  span {
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 3px;
  }

  @media only screen and (max-width: 767px) {
    span {
      display: none;
    }

    .w-common-radio {
      width: auto;
    }
  }
}

#createServiceModal .ecommerce-application .grid-view {
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
</style>
