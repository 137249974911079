<template>
    <div class="row">
        <div class="col-12">
            <good-data-table
                ref="orderDetailsTable"
                :mode="'local'"
                :columns="colums"
            />
        </div>
    </div>
</template>

<script>
import { isEmpty } from '@/utilities'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  name: 'OrderDetailsTable',
  components: {
    GoodDataTable
  },
  props: {
    activeOrderDetail: Object
  },
  data: () => ({
    orders: []
  }),
  computed: {
    colums: {
      get() {
        return [
          {
            field: 'product_name',
            label: 'Product Name',
            useHtml: true,
          },
          {
            field: 'note',
            label: 'Note',
            useHtml: true,
          },
          {
            field: 'price',
            label: 'Price',
            useHtml: true,
          },
          {
            field: 'unit',
            label: 'Unit',
            useHtml: true,
          },
          {
            field: 'quantity',
            label: 'Quantity',
            useHtml: true,
          },
          {
            field: 'cost',
            label: 'Cost',
            useHtml: true,
          },
        ]
      },
    },
  },
  methods: {
    getVariations(products) {
      if (!products) return []
      try {
        return Object.entries(JSON.parse(products))
      } catch (error) {
        return []
      }
    },
  },
  watch: {
    activeOrderDetail: {
        handler(nv){
            if(!isEmpty(nv?.invoice?.items)){
                const items = nv.invoice.items
                if(Array.isArray(items)){
                    if(items.length > 0){
                        const dummyRow = {
                            product_name: '', note: '', price: '', unit: '', quantity: '', cost: ''
                        }
                        items.forEach(item => {
                            let product_name, note, price, unit, quantity, cost;
                            product_name = item.product ? item.product.item_name : ''
                            note = item.note
                            price = `${item.item_price} ${nv.currency}`
                            unit = `${item.product && item.product.unit_value ? item.product.unit_value : ''} ${item.product && item.product.main_unit ? item.product.main_unit.name : ''}`
                            quantity = item.quantity
                            cost = `${item.item_price * item.quantity} ${nv.currency}`
                            const product = {
                                product_name, note, price, unit, quantity, cost
                            }
                            this.orders.push(product)
                            // if(!isEmpty(item.cartaddon)){
                            //     const product_addons = []
                            //     let product_name, note, price, unit, quantity, cost;
                            //     if(Array.isArray(item.cartaddon) && item.cartaddon.length > 0){
                            //         product_addons.push(`<div>(${item.cartaddon.length} ${this.$t('addons')})</div>`)
                            //         item.cartaddon.forEach(addon => {
                            //             product_addons.push(`<div>${addon.addon_name}</div>`)
                            //         })
                            //         product_name = product_addons.join('')
                            //         note = item.note
                            //         price = `${addon.addon_price} ${nv.currency}`
                            //         unit = `--`
                            //         quantity = item.quantity
                            //         cost = `${addon.addon_price * item.quantity} ${nv.currency}`
                            //         const product = {
                            //             product_name, note, price, unit, quantity, cost
                            //         }
                            //         this.orders.push(product)
                            //     }
                            // }
                            if(!isEmpty(this.getVariations(item.product_data))){
                                const variations = this.getVariations(item.product_data)
                                for(const {v, k} of variations){

                                }
                                // const product_addons = []
                                // let product_name, note, price, unit, quantity, cost;
                                // product_name = product_addons.join('')
                                // note = ''
                                // price = ''
                                // unit = ''
                                // quantity = ''
                                // cost = ''
                                // const product = {
                                //     product_name, note, price, unit, quantity, cost
                                // }
                                // this.orders.push(product)
                            }
                            this.orders.push(dummyRow)
                        });
                    }
                }
                setTimeout(() => {
                    this.$refs.orderDetailsTable.setLocalData(this.orders)
                }, 500);
            }
        },
        deep: true,
        immediate: true
    }
  }
}
</script>