<template>
  <div
    class="auth-wrapper auth-v2 registration-page"
    :class="{ 'modal-register-page': fromModal }"
    :style="{ backgroundImage: !fromModal ? `url('${$apiUrl}/images/common/login-bg.webp')` : '' }"
  >
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col
        cols="12"
        :md="fromModal ? 12  : 5" 
        :lg="fromModal ? 12  : 4" 
        :xl="fromModal ? 12  : 3" 
        class="d-flex m-auto align-items-center auth-bg justify-content-center align-content-center"
      >
        <div>
          <div v-if="!fromModal" class="w-100 d-flex justify-content-center mt-1">
            <img class="login-img-logo" :src="require('@/assets/images/app-images/mom_r_wide.svg')" />
          </div>
          <div class="w-100 mt-1 d-flex justify-content-center align-content-center align-items-center align-self-center">
            <b-form-group v-if="!fromModal">
              <b-form-radio-group
                id="btn-radios-login-type"
                v-model="currentLoginFor"
                :options="loginTypeOpts"
                button-variant="outline-light"
                name="radio-btn-outline"
                buttons
              />
            </b-form-group>
            <!-- <div class="d-flex justify-content-end align-items-center">
              <locale class="locale-wrapper" style="list-style: none" />
            </div> -->
          </div>
          <!-- form -->
          <validation-observer ref="registrationValidation">
            <b-form
              class="auth-login-form"
              @submit.prevent
            >
              <b-form-group
                :label="$t('SIGN UP VIA')"
                class="text-center"
                 :label-class="fromModal ? 'text-dark fs-1p2rem' : 'text-light fs-1p2rem'"
              >
                <b-form-radio-group
                  v-model="forgetType"
                  :disabled="registrationStep === 2"
                  class="demo-inline-spacing"
                >
                  <b-form-radio value="email">
                    <img
                      class="icon-lable-radio"
                      title="Email"
                      :src="require('@/assets/images/app-images/icons8-mail-94.png')"
                    >
                  </b-form-radio>
                  <b-form-radio value="mobile">
                    <img
                      class="icon-lable-radio"
                      title="Mobile"
                      :src="require('@/assets/images/app-images/icons8-smartphone-94.png')"
                    >
                  </b-form-radio>
                  <b-form-radio value="whatsapp">
                    <img
                      class="icon-lable-radio"
                      title="Whatsapp"
                      :src="require('@/assets/images/app-images/icons8-whatsapp-94.png')"
                    >
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
                v-if="forgetType != 'email'"
                :label="$t('Phone Number')"
                 :label-class="fromModal ? 'text-dark fs-1rem' : 'text-light fs-1rem'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <VuePhoneNumberInput
                    v-model="registrationInfo.userPhoneNumber"
                    :disabled="registrationStep === 2"
                    placeholder="+91 0000000000"
                    :state="errors.length > 0 ? false : null"
                    @update="onUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="forgetType == 'email'"
                :label="$t('Email')"
                 :label-class="fromModal ? 'text-dark fs-1rem' : 'text-light fs-1rem'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="registrationInfo.userEmail"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="registrationStep === 2"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationStep === 2"
                :label="$t('Verify OTP')"
                :label-class="fromModal ? 'text-dark fs-1rem' : 'text-light fs-1rem'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="VerifyOtp"
                  rules="required"
                >
                  <b-form-input
                    v-model="registrationInfo.otp"
                    type="number"
                    :min="1000"
                    :max="9999"
                    :state="errors.length > 0 ? false : null"
                    placeholder="9876"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationStep === 2"
                :label="$t('Password')"
                :label-class="fromModal ? 'text-dark fs-1rem' : 'text-light fs-1rem'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="registrationInfo.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="registrationStep === 2"
                :label="$t('Referral Code (optional)')"
                 :label-class="fromModal ? 'text-dark' : 'text-light'"
              >
                <b-form-input v-model="registrationInfo.referralCode" />
              </b-form-group>
              <!-- submit buttons -->
              <button type="submit" class="w-100 custom-design custom-design-black px-md-3 font-weight-bold tra-black-bg" @click="validationForm">{{ buttonText }}</button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('Already have an account?') }} </span>
            <b-link
              v-if="!fromModal"
              :to="{ name: $route.meta.sharedType == 'provider' ? 'apps-provider-login' : $route.meta.sharedType == 'shop' ? 'apps-shop-login' : 'apps-login' }"
            >
              <span>&nbsp;{{ $t('Log In') }}</span>
            </b-link>
            <b-link
              v-else
              href="javascript: void(0);"
              @click="$emit('hide-registration')"
            >
              <span>&nbsp;{{ $t('Log In') }}</span>
            </b-link>
          </b-card-text>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div
            class="auth-footer-btn d-flex justify-content-center"
            :class="{'mb-3': fromModal}"
          >
            <!-- <facebook-login
              app-id="518236876494478"
              class="face-book-login-button"
              :login-label="''"
              @login="onLogin"
              @get-initial-status="getUserData"
              @sdk-loaded="sdkLoaded"
              :logoutLabel="' '"
            /> -->
            <div>
              <img :src="`${$apiUrl}/images/icons/facebook.svg`" width="44" class="cp" @click="facebookLogin" />
            </div>
            <div id="g_id_onload"></div>
            <div class="g_id_signin ml-1"></div>
          </div>
          <b-card-text class="text-left my-1">
            <span>{{ $t('By continuing, you agree to our') }} </span>
            <b-link :to="{ path: '/terms-and-condition' }">
              <span>&nbsp;{{ $t('Terms of Use') }}</span>
            </b-link>
            {{ $t('and') }}
            <b-link :to="{ path: '/privacy-policy' }">
              <span>&nbsp;{{ $t('Privacy Policy') }}</span>
            </b-link>
          </b-card-text>
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { ModelSelect } from 'vue-search-select'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useLoginUi } from './useAuth'
import { fbLogin } from './init-facebook-sdk'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import { useUserUi } from '../users/useUser'
import jwt_decode from "jwt-decode";
import { isEmpty } from '@/utilities'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
    Locale,
    ModelSelect
  },
  mixins: [togglePasswordVisibility],
  props: {
    fromModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSocialLogin: false,
      isConnected: false,
      FB: null,
      registrationInfo: {
        password: null,
        userEmail: null,
        confirmPassword: null,
        userPhoneNumber: null,
        referralCode: null,
        otp: null,
        phoneCode: null,
      },
      registrationSteps: {
        sendOtp: true,
        verifyOtp: false,
        normal: false,
      },
      registrationStep: 1,
      phoneNumberPayload: null,
      status: '',
      forgetType: 'whatsapp',
      sideImg: require('@/assets/images/app-images/login-animation.png'),
      required,
      email,
      currentLoginFor: '',
      loginTypeOpts: [
        { text: 'User', value: 'user' },
        { text: 'Shop', value: 'shop' },
        { text: 'Provider', value: 'provider' },
      ]
    }
  },
  computed: {
    buttonText() {
      // eslint-disable-next-line no-nested-ternary
      return 'Sign up'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/app-images/login-animation.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  watch: {
    'registrationInfo.countryCode': {
      immediate: true,
      deep: true,
      handler() {
        if (this.registrationInfo && this.registrationInfo.countryCode) {
          this.onCountryChange()
        }
      },
    },
    currentLoginFor(nv){
      if(this.fromModal) return false;
      if(nv === 'user'){
        this.$router.push({
          name: 'apps-registration'
        })
        return;
      }else {
        this.$router.push({
          name: `apps-${nv}-registration`
        })
        return;
      }
    }
  },
  beforeMount(){
    this.loadFacebookSDK()
  },
  mounted() {
    this.currentLoginFor = this.$route.meta.sharedType;
    this.registrationInfo.referralCode = localStorage.getItem('referral_code') || ''
    window.google.accounts.id.initialize({
      client_id: this.$superApp.google.clientId,
      callback: (res) => {
        this.OnGoogleAuthSuccess(res.credential)
      }
    });
    const parent = document.getElementById('g_id_onload');
    window.google.accounts.id.renderButton(parent, {
      theme: "outline",
      type: "icon",
      shape: "circle",
      size: "large",
    });
    window.google.accounts.id.prompt();
    const { countryList } = useUserUi()
    countryList()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.countryList = data.responseData
          console.log(data.responseData, 'data.responseData');
          this.countryOptions = data.responseData.map(x => ({
            text: x.country_name,
            value: x.id,
          }))
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
    if (localStorage.getItem('userSocialInfo')) {
      const socialInfo = JSON.parse(localStorage.getItem('userSocialInfo'))
      this.registrationInfo.userEmail = socialInfo.email
      this.registrationInfo.firstName = socialInfo.first_name
      this.registrationInfo.lastName = socialInfo.last_name
    }
  },
  methods: {
    loadFacebookSDK() {
      window.fbAsyncInit = () => {
        FB.init({
          appId: '518236876494478', // Replace with your Facebook app ID
          cookie: true,
          xfbml: true,
          version: 'v20.0'
        });
        FB.AppEvents.logPageView();
      };

      (function(d, s, id){
        let js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_UK/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    facebookLogin() {
      FB.login(response => {
        if (response.authResponse) {
          this.handleFBLogin(response.authResponse);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, {scope: 'public_profile,email'});
    },
    handleFBLogin(authResponse) {
      const thisClass = this
      FB.api('/me', { fields: 'id,first_name,last_name,email,link,gender,locale,picture' }, function(response) {
        console.log(response, 'responseq');
        thisClass.makeSocialLogin({
          social_unique_id: response.id,
          login_by: 'FACEBOOK',
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          picture: response.picture?.data?.url ?? null,
        })
      });
    },
    OnGoogleAuthSuccess (idToken) {
      const payload = jwt_decode(idToken)
      this.makeSocialLogin({
        social_unique_id: payload.sub,
        login_by: 'GOOGLE',
        first_name: payload.given_name,
        last_name: payload.family_name,
        email: payload.email,
        picture: payload.picture,
      })
    },
    OnGoogleAuthFail () {
      showDangerNotification(this, 'Failed to login !!')
    },
    getUserData() {
      if (!this.FB) return
      this.FB.api('/me', 'GET', { fields: 'id,first_name,last_name,email,link,gender,locale,picture' }, response => {
        if (response.error) {
          showDangerNotification(this, 'Failed to login !!')
          return
        }else if(response.email == null || response.email == '' || typeof response.email === typeof undefined){
          showDangerNotification(this, 'Email address not found on your Facebook account try alternate method for register (Manual/Google)!!')
          return false
        }
        if (window.socialLoginParams) {
          window.socialLoginParams({
            social_unique_id: response.id,
            login_by: 'FACEBOOK',
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
            picture: response.picture && response.picture.data ? response.picture.data.url : '',
          })
        }
      })
    },
    sdkLoaded(payload) {
      this.FB = payload.FB
      if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
    },
    makeSocialLogin(payload) {
      if(payload.login_by == 'GOOGLE' && payload.login_by == 'FACEBOOK'){
        this.isSocialLogin = true
      }
      const formData = new FormData()
      formData.append('referral_code', this.registrationInfo.referralCode)
      formData.append('social_unique_id', payload.social_unique_id)
      formData.append('login_by', payload.login_by)
      formData.append('first_name', payload.first_name)
      formData.append('last_name', payload.last_name)
      formData.append('email', payload.email)
      formData.append('picture', payload.picture)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('gender', this.registrationInfo.gender)
      window.localStorage.setItem('userSocialInfo', JSON.stringify(payload))
      if(typeof payload.login_by === typeof undefined || (payload.login_by != 'GOOGLE' && payload.login_by == 'FACEBOOK')){
        formData.append('password', payload.social_unique_id)
        formData.append('password_confirmation', payload.social_unique_id)
      }
      if (this.registrationInfo.phoneCode) {
        formData.append('country_code', this.registrationInfo.phoneCode)
        formData.append('iso2', `+${this.registrationInfo.phoneCode}`)
      }
      if (this.registrationInfo.store_name) {
        formData.append('store_name', this.registrationInfo.store_name)
      }
      if (this.registrationInfo.store_type_id) {
        formData.append('store_type_id', this.registrationInfo.store_type_id)
      }
      if (this.$route.meta.sharedType === 'provider') {
        if (Array.isArray(this.registrationInfo.provider_service_id)) {
          this.registrationInfo.provider_service_id.forEach(item => {
            formData.append('provider_service_id[]', item.id)
          })
        }
        if (this.registrationInfo.company_name) {
          formData.append('company_name', this.registrationInfo.company_name)
        }
      }
      const { userRegistration, shopRegistration, providerRegistration } = useLoginUi()
      let callType = null
      if (this.$route.meta.sharedType === 'shop') {
        callType = shopRegistration
      } else if (this.$route.meta.sharedType === 'provider') {
        callType = providerRegistration
      } else {
        callType = userRegistration
      }
      showLoader()
        callType({
          type: this.$route.meta.sharedType,
          data: formData,
        })
          .then(({ data }) => {
            if (data.statusCode === '200') {
              showSuccessNotification(this, 'Registration Successful')
              this.setLoginInformations(data)
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
    },
    setLoginInformations(responseLogin) {
      useJwt.setToken(responseLogin.responseData.access_token)
      useJwt.setRefreshToken(responseLogin.responseData.access_token)
      window.SuperApp.getters.setUserInfo(JSON.stringify(responseLogin.responseData.user))
      window.SuperApp.getters.setUserType(this.$route.meta.sharedType ? this.$route.meta.sharedType : 'user')
      window.SuperApp.getters.setUserAccess(responseLogin.responseData.access_token)
      showSuccessNotification(this, 'Logged in successfully')
      window.SuperApp.getters.setUserLoggedIn(true)
      if (responseLogin.responseData.user.city_id) {
        localStorage.setItem('userSelectedCityId', responseLogin.responseData.user.city_id)
      }
      if (!document.body.classList.contains('modal-open')) {
        window.SuperApp.helper.changeTranslation(this, window.SuperApp.getters.userLang())
        if (this.$route.query.returnUrl) {
          this.$router.push({ path: this.$route.query.returnUrl })
        } else if (window.SuperApp.getters.isUser()) this.$router.push({ name: 'user-profile' })
        else if (window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()) this.$router.push({ name: 'provider-profile' })
        else if (window.SuperApp.getters.isProvider() && window.SuperApp.getters.isAgent()) this.$router.push({ name: 'provider-profile' })
        else if (window.SuperApp.getters.isStore()) this.$router.push({ name: 'shop-profile' })
        else if (window.SuperApp.getters.isAdmin()) this.$router.push({ name: 'admin-cmspages-faq' })
        window.SuperApp.helper.requestPermission()
      } else {
        window.location.reload()
      }
    },
    loginWithFacebook() {
      fbLogin()
    },
    onCountryChange() {
      this.cityOptions = []
      let cityList = []
      const { cityByCountry } = useUserUi()
      showLoader()
      cityByCountry(this.registrationInfo.countryCode)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const isCountryExist = this.countryList.find(x => x.id === this.registrationInfo.countryCode)
            if (isCountryExist) {
              isCountryExist.city = data.responseData
              cityList = isCountryExist.city.map(x => ({
                text: x.city_name,
                value: x.id,
              }))
            }
            this.registrationInfo.cityCode = ''
            cityList.forEach(element => {
              this.cityOptions.push({
                text: element.text,
                value: element.value,
              })
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
      this.registrationInfo.phoneCode = payload.countryCallingCode
    },
    sendOTP() {
      const { userSendOTP } = useLoginUi()
      showLoader()
      let payload = {}
      if (this.forgetType === 'email') {
        payload = {
          email: this.registrationInfo.userEmail,
        }
      } else {
        payload = {
          country_code: this.registrationInfo.phoneCode,
          mobile: this.phoneNumberPayload.nationalNumber,
        }
      }
      userSendOTP({
        data: {
          ...payload,
          salt_key: window.SuperApp.getters.saltKey(),
          account_type: this.forgetType,
        },
        query: this.$route.meta.sharedType ? this.$route.meta.sharedType : 'user',
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.registrationStep = 2
            showSuccessNotification(this, 'OTP sent successfully')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    registerUser() {
      const formData = new FormData()
      if(!isEmpty(this.phoneNumberPayload.nationalNumber)){
        formData.append('mobile', this.phoneNumberPayload.nationalNumber)
      }
      if(!isEmpty(this.registrationInfo.userEmail)){
        formData.append('email', this.registrationInfo.userEmail)
      }
      formData.append('otp', this.registrationInfo.otp)
      formData.append('referral_code', this.registrationInfo.referralCode)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      formData.append('password', this.registrationInfo.password)
      if (this.registrationInfo.phoneCode) {
        formData.append('country_code', this.registrationInfo.phoneCode)
        formData.append('iso2', `+${this.registrationInfo.phoneCode}`)
      }
      const { userRegistration, shopRegistration, providerRegistration } = useLoginUi()
      let callType = null
      if (this.$route.meta.sharedType === 'shop') {
        callType = shopRegistration
      } else if (this.$route.meta.sharedType === 'provider') {
        callType = providerRegistration
      } else {
        callType = userRegistration
      }
      showLoader()
      callType({
        type: this.$route.meta.sharedType,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Registration Successful')
            this.setLoginInformations(data)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    validationForm() {
      this.$refs.registrationValidation.validate().then(success => {
        if (success) {
          if (this.forgetType !== 'email') {
            if (!this.phoneNumberPayload) {
              showDangerNotification(this, 'Please enter phone number')
              return
            }
            if (!this.phoneNumberPayload.isValid) {
              showDangerNotification(this, 'Please enter proper phone number')
              return
            }
          } else if (!this.registrationInfo.userEmail) {
            showDangerNotification(this, 'Please enter email')
            return
          }
          if (this.registrationStep === 2) {
            this.registerUser()
          } else if (this.registrationStep === 1) {
            this.sendOTP()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-footer-btn {
  button {
    border: 0;
    background-color: transparent !important;
  }
}

.registration-page {
  .multiselect__tags {
    border: 1px solid #d8d6de !important;
  }
}

.auth-wrapper.auth-v2.modal-register-page {
  min-height: auto !important;
  padding-bottom: 20px;

  .auth-inner {
    height: auto;
  }
}
</style>

<style scoped>
.demo-inline-spacing {
  justify-content: center;
}
</style>

<style scoped>
.auth-v2{
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.third_party_login{
  height: 32px;
  width: 32px;
}
</style>