<template>
  <div class="b-form-spinbutton form-control d-flex align-items-stretch form-control-sm">
    <button
      class="btn btn-primary border-0 p-1 d-flex justify-content-center align-items-center mobile-p-0"
      @click="decrement"
    >
      <feather-icon
        icon="MinusIcon"
        size="21"
      />
    </button>
    <input
      v-model.number="currentValue"
      type="number"
      disabled
      class="flex-grow-1 align-self-center border-left border-right form-control text-center"
    >
    <button
      class="btn btn-primary border-0 p-1 d-flex justify-content-center align-items-center mobile-p-0"
      @click="increment"
    >
      <feather-icon
        icon="PlusIcon"
        size="21"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    cartProduct: {
      type: [Object, undefined, null],
      default: () => {},
    },
  },
  data() {
    return {
      currentValue: 0,
    }
  },
  watch: {
    cartProduct: {
      immediate: true,
      handler() {
        if (this.cartProduct && this.cartProduct.quantity) {
          this.currentValue = this.cartProduct.quantity
        }
      },
    },
  },
  methods: {
    increment() {
      this.$emit('value-changed', {
        value: this.currentValue + 1,
        type: '+',
        product: this.product,
        fromSpin: true,
      })
    },
    decrement() {
      let currentValue = parseFloat(`${this.currentValue}`)
      currentValue -= 1
      if (currentValue < 0) {
        currentValue = 0
      }
      this.$emit('value-changed', {
        value: currentValue,
        type: '-',
        product: this.product,
        fromSpin: true,
      })
    },
  },
}
</script>
