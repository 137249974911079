<template>
  <div class="mx-auto">
    <img
      v-if="type === 'product' || type === 'cart'"
      class="no-store-found-image"
      :src="require('@/assets/images/app-images/NoOptionAvailable.svg')"
    >
    <img
      v-if="type === 'menu'"
      class="no-store-found-image"
      :src="require('@/assets/images/app-images/NoMenu.svg')"
    >
    <img
      v-if="type === 'overview'"
      class="no-store-found-image"
      :src="require('@/assets/images/app-images/NoOverview.svg')"
    >
    <img
      v-if="type === 'photos'"
      class="no-store-found-image"
      :src="require('@/assets/images/app-images/NoPhotos.svg')"
    >
    <img
      v-if="type === 'reviews'"
      class="no-store-found-image"
      :src="require('@/assets/images/app-images/NoReviews.svg')"
    >
    <p
      v-if="type === 'product'"
      class="text-center mt-3"
    >
      {{ $t('Sorry, Currently no options are available for your selection') }}
    </p>
    <p
      v-if="type === 'cart'"
      class="text-center mt-3"
    >
      {{ $t('Sorry, No items found in your cart') }}
    </p>
    <p
      v-if="type === 'overview'"
      class="text-center mt-3"
    >
      {{ $t('No Overview Available') }}
    </p>
    <p
      v-if="type === 'reviews'"
      class="text-center mt-3"
    >
      {{ $t('No Review Available') }}
    </p>
    <p
      v-if="type === 'photos'"
      class="text-center mt-3"
    >
      {{ $t('No Photos Available') }}
    </p>
    <p
      v-if="type === 'menu'"
      class="text-center mt-3"
    >
      {{ $t('No Menu Available') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
  },
}
</script>
