<template>
  <div class="user-modal-component">
    <b-modal
      id="loginModal"
      v-model="viewLoginModal"
      :no-close-on-backdrop="true"
      :title="$t('Login')"
      @hidden="hideLogin"
    >
      <LoginComponent @showRegistration="showRegistration" />
    </b-modal>
    <b-modal
      v-model="viewRegisterModal"
      :no-close-on-backdrop="true"
      :title="$t('Registration')"
      hide-footer
      @hidden="hideRegistration"
    >
      <registration
        :from-modal="true"
        @hide-registration="hideRegistration"
      />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Registration from '@/views/auth/Registration.vue'
import LoginComponent from '@/views/auth/LoginComponent.vue'
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    Registration,
    LoginComponent,
    BModal
  },
  data() {
    return {
      viewRegisterModal: false,
      viewLoginModal: false,
    }
  },
  methods: {
    showModal() {
      this.viewLoginModal = true
    },
    hideLogin() {
      this.viewLoginModal = false
    },
    showRegistration() {
      this.viewRegisterModal = true
    },
    hideRegistration() {
      this.viewRegisterModal = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-footer-btn {
  button {
    border: 0;
    background-color: transparent !important;
  }
}
</style>
