<template>
  <div class="wrap-custom-calender">
    <login-modal ref="loginModal" />
    <div class="wrap-calender-top-header py-2 d-flex justify-content-center align-items-center">
      <div class="icon-calenders d-flex justify-content-center align-items-center">
        <feather-icon
          icon="ChevronLeftIcon"
          class="cursor-pointer"
          size="16"
          @click="previousDate(activeDate, 1, true)"
        />
        <feather-icon
          icon="ChevronRightIcon"
          class="cursor-pointer"
          size="16"
          @click="nextDate(activeDate, 1, true)"
        />
        <p class="mb-0">
          {{ getDayFromDate }}
        </p>
      </div>
    </div>
    <div class="wrap-calender-header">
      <div
        v-for="(day, index) of fiveDays"
        :key="index"
        class="header-wrapper cursor-pointer"
        :class="{ active: isSameDate(day) }"
        @click="setDate(day)"
      >
        <p class="mb-0">
          {{ getDays(day) }}
        </p>
        <h4>{{ getDate(day) }}</h4>
      </div>
    </div>
    <div class="wrap-timelines py-2 px-1">
      <div
        v-for="(item, lineIndex) of getTimeLines"
        :key="lineIndex"
        class="timeline m-0"
      >
        <h4 v-if="item.slots && item.slots.length > 0">
          {{ item.key }}
        </h4>
        <div
          v-if="item.slots && item.slots.length > 0"
          class="grid-timeline"
        >
          <p
            v-for="(slot, index) of item.slots"
            :key="index"
            class="mb-0 cursor-pointer"
            :class="{ active: activeTimeSlot === slot }"
            @click="setTimeSlot(slot)"
          >
            {{ slot }}
          </p>
        </div>
      </div>
      <p
        v-if="getTimeLines.length == 0"
        class="mb-0 text-center"
      >
        {{ $t('We are not accepting request/orders today') }}
      </p>
    </div>
    <div
      v-if="!hideSubmit"
      class="wrap-footer text-center bg-white pt-2 pb-2 mb-2"
    >
      <button
        class="btn btn-primary btn-calender-submit"
        @click="submitData"
      >
        {{ $t('SUBMIT') }}
      </button>
    </div>
  </div>
</template>

<script>
import LoginModal from '@core/layouts/components/app-navbar/components/LoginModal.vue'
import { isUserLoggedIn } from '@/auth/utils'
import moment from 'moment'
/* eslint-disable radix */

export default {
  components: {
    LoginModal,
  },
  props: {
    timing: {
      type: Object,
      default: () => {},
    },
    allTimings: {
      type: [Object, Array],
      default: () => [],
    },
    storeSlotInfo: {
      type: [Object, Array],
      default: () => {},
    },
    hideSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeDate: new Date(),
      fiveDays: [],
      activeTimeSlot: '',
    }
  },
  computed: {
    activeDaySlot() {
      const timeslotData = Array.isArray(this.allTimings) ? this.allTimings : this.allTimings.timings
      if (Array.isArray(timeslotData)) {
        if (timeslotData[0] && timeslotData[0].day === 'ALL') {
          return timeslotData[0]
        }
        if (timeslotData[0] && timeslotData[0].store_day === 'ALL') {
          return timeslotData[0]
        }
        const currentDay = moment(this.activeDate).format('ddd').toUpperCase()
        let findCurrentSlot = timeslotData.find(x => x.day === currentDay)
        if (!findCurrentSlot) {
          findCurrentSlot = timeslotData.find(x => x.store_day === currentDay)
        }
        return findCurrentSlot
      }
      return null
    },
    getTimeLines() {
      const timing = this.activeDaySlot
      if (!timing) {
        return []
        // timing = this.timing
      }
      if (!timing) return []
      if (!timing.store_start_time && timing.start_time) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        timing.store_start_time = timing.start_time
      }
      if (!timing.store_end_time && timing.end_time) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        timing.store_end_time = timing.end_time
      }
      if (!timing.store_start_time || !timing.store_end_time) return []
      let startTime = timing.store_start_time
      let endTime = timing.store_end_time
      if (endTime === '00:00:00') {
        endTime = '23:59:59'
      }
      if (new Date(moment(startTime, 'HH:mm')) > new Date(moment(endTime, 'HH:mm'))) {
        endTime = timing.store_start_time
        startTime = timing.store_end_time
      }
      const timings = this.returnTimesInBetween(startTime, endTime)
      const allTimings = []
      const morningSlots = [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
      ]
      const afternoonSLots = ['12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30']
      const eveningSlots = ['16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']
      allTimings.push({
        key: 'Morning',
        slots: morningSlots.filter(x => timings.includes(x)),
      })
      allTimings.push({
        key: 'Afternoon',
        slots: afternoonSLots.filter(x => timings.includes(x)),
      })
      allTimings.push({
        key: 'Evening',
        slots: eveningSlots.filter(x => timings.includes(x)),
      })
      return allTimings
    },
    getDayFromDate() {
      return moment(this.activeDate).format('MMM DD, YYYY')
    },
  },
  mounted() {
    this.getFiveDays()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM-DD-YYYY')
    },
    isSlotBooked(slotTime) {
      if (!this.storeSlotInfo) return false
      if (!this.storeSlotInfo.timeslots) return false
      if (this.storeSlotInfo.timeslots.length === 0) return false
      return this.storeSlotInfo.timeslots.find(x => x.time === slotTime && this.formatDate(x.booking_date) === this.formatDate(this.activeDate))
    },
    returnTimesInBetween(start, end) {
      const timesInBetween = []

      // Format the time
      const startTime = moment(start, 'HH:mm')

      // Format the end time and the next day to it
      const endTime = moment(end, 'HH:mm')

      // Loop over the times - only pushes time with 30 minutes interval
      while (startTime < endTime) {
        // Push times
        timesInBetween.push(startTime.format('HH:mm'))
        // Add interval of 30 minutes
        startTime.add(30, 'minutes')
      }

      return timesInBetween.sort((a, b) => a.localeCompare(b))
    },
    setTimeSlot(slot) {
      if (this.activeTimeSlot !== slot) this.activeTimeSlot = slot
      else this.activeTimeSlot = ''
    },
    submitData() {
      if (!isUserLoggedIn()) {
        this.$refs.loginModal.showModal()
        return
      }
      this.$swal({
        title: this.$t('You want to book this slot'),
        iconHtml: this.$helpers.swalIcon('schedule.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('submit', this.getSubmitPayload())
        }
      })
    },
    getSubmitPayload() {
      return {
        time: this.activeTimeSlot,
        day: new Date(this.activeDate).getDate(),
        month: new Date(this.activeDate).getMonth() + 1,
        year: new Date(this.activeDate).getFullYear(),
      }
    },
    setDate(date) {
      this.activeDate = new Date(date)
      this.getFiveDays()
    },
    getDays(date) {
      return moment(date).format('ddd')
    },
    getDate(date) {
      return new Date(date).getDate()
    },
    isSameDate(date) {
      return moment(date, 'DD-MM-YYYY').isSame(moment(this.activeDate, 'DD-MM-YYYY'))
    },
    getFiveDays() {
      this.fiveDays = [
        new Date(moment(this.activeDate).add(-2, 'days')),
        new Date(moment(this.activeDate).add(-1, 'days')),
        this.activeDate,
        new Date(moment(this.activeDate).add(1, 'days')),
        new Date(moment(this.activeDate).add(2, 'days')),
      ]
    },
    nextDate(date, days, changeInitial) {
      const newDate = new Date(date)
      newDate.setDate(newDate.getDate() + days)
      if (changeInitial) {
        this.activeDate = newDate
        this.getFiveDays()
      }
      return newDate
    },
    previousDate(date, days, changeInitial) {
      const newDate = new Date(date)
      newDate.setDate(newDate.getDate() - days)
      if (changeInitial) {
        this.activeDate = newDate
        this.getFiveDays()
      }
      return newDate
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap-custom-calender {
  background-color: #f5f6fa;
  box-shadow: rgb(28 28 28 / 12%) 0px 2px 8px;

  .timeline .booked {
    pointer-events: none;
    background: deepskyblue;
    border-color: cadetblue;
    color: #fff;
  }

  .btn-calender-submit {
    border-radius: 30px;
    border-color: #1cd18f !important;
    box-shadow: 0 4px 18px -4px #1cd18f;
    background-color: #1cd18f !important;
  }

  .wrap-calender-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    background-color: #666ee8;
    border-radius: 50px;
    text-align: center;
    align-items: center;

    p,
    h4 {
      color: #fff;
    }

    .header-wrapper.active {
      background-color: #fff;
      border-radius: 15px;
      margin: 4px 12px;

      p,
      h4 {
        color: #666ee8;
      }
    }
  }

  .wrap-timelines {
    background-color: #f5f6fa;
  }

  .grid-timeline {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-column-gap: 20px;
    margin-bottom: 30px;

    p {
      padding: 5px 8px;
      border-radius: 4px;
      border: 1px solid #000;
      text-align: center;
    }

    p.active {
      color: #fff;
      background: #666ee8;
      border-color: #666ee8;
    }
  }
}
</style>
