<template>
  <div class="menu-creation ecommerce-application">
    <div class="wrap-online-order-section">
      <no-data-available
        v-if="!optionsLoaded.products"
        :type="'product'"
      />
      <div
        v-if="optionsLoaded.products"
        class="row"
      >
        <div class="col-md-12">
          <section
            v-if="!isMobile"
            id="ecommerce-header"
          >
            <div class="row">
              <div class="col-sm-12">
                <div class="ecommerce-header-items">
                  <div class="result-toggler">
                    <div class="search-results">
                      {{ $t('Showing') }} {{ filteredStore.length }} {{ $t('of') }} {{ storeList.provider_service ? storeList.provider_service.length : 0 }} {{ $t('results') }}
                    </div>
                  </div>
                  <div class="view-options d-flex">
                    <!-- Item View Radio Button Group  -->
                    <b-form-radio-group
                      v-model="itemView"
                      class="ml-1 list item-view-radio-group"
                      buttons
                      size="sm"
                      button-variant="outline-primary"
                    >
                      <b-form-radio
                        v-for="option in itemViewOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        <feather-icon
                          :icon="option.icon"
                          size="18"
                        />
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="ecommerce-searchbar mt-1">
            <b-row>
              <b-col cols="12">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Search..."
                    class="search-product"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </div>

          <section :class="isMobile ? 'responsive-list-view' : itemView">
            <service-section
              v-for="product in filteredStore"
              :key="product.id"
              :that="that"
              :product="product"
              class="mb-1"
            />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend } from 'bootstrap-vue'
import NoDataAvailable from '@/views/users/shared/NoDataAvailable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from '../useProvider'
import ServiceSection from './ServiceSection.vue'
import { isEmpty } from '@/utilities'
export default {
  components: {
    NoDataAvailable,
    BFormRadioGroup,
    ServiceSection,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    storeList: {
      type: [Object, Array],
      default: () => [],
    },
    phoneUserInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLoaded: {
        products: false,
      },
      searchQuery: '',
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      userCartItems: {
        carts: [],
      },
    }
  },
  computed: {
    that() {
      return this
    },
    isMobile() {
      return this.$store.getters['app/windowSize'] <= 767
    },
    filteredStore() {
      if (this.searchQuery) {
        return this.storeList.provider_service.filter(x => {
          const serviceName = !isEmpty(x.title) ? x.title : x.main_service.service_name;
          return x.main_service && serviceName.toLowerCase().includes(this.searchQuery.toLowerCase())
        })
      }
      return this.storeList.provider_service
    },
    agentInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isProviderAgent() {
      return this.agentInfo.parent_type === 'PROVIDER'
    },
  },
  watch: {
    storeList: {
      immediate: true,
      handler() {
        if (!this.optionsLoaded.products && this.storeList.provider_service) {
          this.optionsLoaded.products = this.storeList.provider_service.length > 0
        }
      },
    },
  },
  mounted() {
    const editCartExist = this.$store.state['app-ecommerce'].editRequestProvider
    if (editCartExist && editCartExist.carts) {
      this.userCartItems = window.SuperApp.actions.cloneDeep(editCartExist)
      this.$emit('cart-info', this.userCartItems)
    } else {
      this.getCartList()
    }
  },
  methods: {
    isEmpty,
    toggleViewMore(product) {
      this.$emit('toggle-more', product)
    },
    getServiceDescription(product) {
      return product.description ? product.description : product.main_service.description
    },
    getText(text) {
      return text.slice(0, 50)
    },
    canShowLabel(text) {
      return text && `${text}`.length > 50
    },
    isAddedToCart(product) {
      return this.userCartItems.carts && this.userCartItems.carts.find(x => x.provider_service && x.provider_service.id === product.id)
    },
    addedProduct(product) {
      return this.userCartItems.carts.find(x => x.provider_service && x.provider_service.id === product.id)
    },
    removeItemFromCart(product) {
      const cartProduct = this.userCartItems.carts.find(x => x.provider_service && x.provider_service.id === product.id)
      const formData = new FormData()
      formData.append('cart_id', cartProduct.id)
      formData.append('user_id', cartProduct.user_id)

      const { providerRemoveCart, providerAgentRemoveCart } = useProviderUi()
      const callType = this.isProviderAgent ? providerAgentRemoveCart : providerRemoveCart
      showLoader()
      callType(formData)
        .then(({ data }) => {
          if (data) {
            // eslint-disable-next-line no-param-reassign
            data.total_price_original = window.SuperApp.actions.cloneDeep(data.total_price)
            this.userCartItems = data
            this.$emit('cart-info', this.userCartItems)
          } else if (data.message) {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    addItemToCart(product) {
      const formData = new FormData()
      formData.append('item_id', product.id)
      const cartProduct = this.userCartItems.carts.find(x => x.provider_service && x.provider_service.id === product.id)
      if (this.addedProduct(product) && this.addedProduct(product).quantity === 0) {
        if (cartProduct) {
          this.removeItemFromCart(product)
          return
        }
      }
      formData.append('qty', this.addedProduct(product) ? this.addedProduct(product).quantity : 1)
      formData.append('user_id', this.phoneUserInfo.id)
      if (this.phoneUserInfo.country_id) formData.append('country_id', this.phoneUserInfo.country_id)
      if (this.phoneUserInfo.country_code) formData.append('country_code', this.phoneUserInfo.country_code)
      if (this.userCartItems.carts && this.userCartItems.carts.length > 0) {
        if (cartProduct) {
          formData.append('repeat', 1)
        }
      } else {
        formData.append('repeat', 0)
      }
      formData.append('city_id', this.phoneUserInfo.city_id)
      if (cartProduct) {
        formData.append('cart_id', cartProduct.id)
      }

      const { providerAddCart, providerAgentAddCart } = useProviderUi()
      const callType = this.isProviderAgent ? providerAgentAddCart : providerAddCart
      showLoader()
      callType(formData)
        .then(({ data }) => {
          if (data) {
            // eslint-disable-next-line no-param-reassign
            data.total_price_original = window.SuperApp.actions.cloneDeep(data.total_price)
            this.userCartItems = data
            this.$emit('cart-info', this.userCartItems)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getCartList(extraParams = '') {
      if (this.phoneUserInfo && !this.phoneUserInfo.id) {
        return
      }
      const editCartExist = this.$store.state['app-ecommerce'].editRequestProvider
      if (editCartExist && editCartExist.carts) {
        return
      }
      const { providerCartList, providerAgentCartList } = useProviderUi()
      const callType = this.isProviderAgent ? providerAgentCartList : providerCartList
      showLoader()
      callType(`?user_id=${this.phoneUserInfo.id}${extraParams}`)
        .then(({ data }) => {
          if (data) {
            // eslint-disable-next-line no-param-reassign
            data.total_price_original = window.SuperApp.actions.cloneDeep(data.total_price)
            this.userCartItems = data
            this.$emit('cart-info', this.userCartItems)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-ecommerce.scss';

@media only screen and (max-width: 767px) {
  #serviceCreationModal {
    padding: 0;

    .app-collapse-body {
      padding: 0 !important;
    }

    .show-list-view {
      display: none;
    }

    .b-form-spinbutton {
      .btn-primary {
        background: transparent !important;
        color: #000000 !important;
      }
    }

    .collapse-panel-card-wrapper {
      box-shadow: none;
    }

    .collapse.show .card-body .card {
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
      margin-bottom: 10px;
    }

    .ecommerce-card .item-img .zoomer img {
      height: 130px !important;
      object-fit: cover;
      width: 100% !important;
      box-shadow: rgb(28 28 28 / 12%) 0px 2px 8px;
      border-radius: 20px;
    }

    .no-store-found-image {
      width: 250px;
    }

    .modal-body {
      max-height: 90vh;
      overflow-y: auto;
      height: 90vh;
    }

    button.cart-item-qty {
      padding: 4px;
    }

    .ecommerce-cards-product {
      overflow: hidden;
      display: grid;
      grid-template-columns: 2fr 3fr;
    }

    .wrap-online-order-section .responsive-list-view {
      .show-list-view {
        display: block;
      }

      .ecommerce-card .item-img img {
        height: 165px;
        width: 100%;
        border-bottom-left-radius: calc(0.428rem - 1px);
        border-top-right-radius: 0;
      }

      .card:not(.collapse-panel-card-wrapper) {
        overflow: hidden;
        display: grid;
        grid-template-columns: 2fr 3fr;
      }

      .item-description,
      .item-company {
        display: none;
      }

      .offer-visible {
        display: block;
      }

      .item-options {
        display: block;
        margin: auto;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.wrap-store-review .reviews:last-child {
  border-bottom: 0 !important;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.result-toggler {
  height: 40px;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
  padding-top: 0;
}

.ecommerce-application .list-view .ecommerce-card .item-img img {
  border-top-right-radius: 0;
}

.list-view .pt-pb {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
</style>
