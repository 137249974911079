/* eslint-disable eol-last */
import store from '@/store'

export const useProvider = () => {}

export const useProviderUi = () => {
  const providerProfile = () => store.dispatch('app-provider/getProviderProfile')

  const getProviderAgentProfile = () => store.dispatch('app-provider/getProviderAgentProfile')

  const updateProviderProfileInfo = formData => store.dispatch('app-provider/updateProviderProfileInfo', formData)

  const providerChangePassword = payload => store.dispatch('app-provider/changeProviderPassword', payload)

  const providerBankDetails = () => store.dispatch('app-provider/getProviderBankDetails')

  const editBankDetails = payload => store.dispatch('app-provider/editBankDetails', payload)

  const addBankDetails = payload => store.dispatch('app-provider/addBankDetails', payload)

  const getProviderList = () => store.dispatch('app-provider/getProviderList')

  const providerCards = () => store.dispatch('app-provider/getProviderCards')

  const getProviderAgentById = payload => store.dispatch('app-provider/getProviderAgentById', payload)

  const updateAgentsProfile = payload => store.dispatch('app-provider/updateAgentsProfile', payload)

  const addAgentsProfile = payload => store.dispatch('app-provider/addAgentsProfile', payload)

  const deleteAgentsProfile = payload => store.dispatch('app-provider/deleteAgentsProfile', payload)

  const providerDisputeStatus = payload => store.dispatch('app-provider/providerDisputeStatus', payload)

  const createServiceDispute = payload => store.dispatch('app-provider/createServiceDispute', payload)

  const providerTripServiceHistory = payload => store.dispatch('app-provider/providerTripServiceHistory', payload)

  const providerRequestDetail = payload => store.dispatch('app-provider/providerRequestDetail', payload)

  const providerAdminServices = () => store.dispatch('app-provider/providerAdminServices')

  const providerListDocument = payload => store.dispatch('app-provider/providerListDocument', payload)

  const deleteProviderDocument = payload => store.dispatch('app-provider/deleteProviderDocument', payload)

  const uploadProviderDocument = payload => store.dispatch('app-provider/uploadProviderDocument', payload)

  const providerShopType = () => store.dispatch('app-provider/providerShopType')

  const providerRideType = () => store.dispatch('app-provider/providerRideType')

  const providerTotalServices = () => store.dispatch('app-provider/providerTotalServices')

  const providerVehicleAdd = payload => store.dispatch('app-provider/providerVehicleAdd', payload)

  const providerVehicleEdit = payload => store.dispatch('app-provider/providerVehicleEdit', payload)

  const providerRequestList = () => store.dispatch('app-provider/providerRequestList')

  const providerRequestHistoryList = payload => store.dispatch('app-provider/providerRequestHistoryList', payload)

  const providerAgentList = () => store.dispatch('app-provider/providerAgentList')

  const updateProviderRequest = payload => store.dispatch('app-provider/updateProviderRequest', payload)

  const providerVehicleAddService = payload => store.dispatch('app-provider/providerVehicleAddService', payload)

  const providerVehicleEditService = payload => store.dispatch('app-provider/providerVehicleEditService', payload)

  const upateProviderAgentStatus = payload => store.dispatch('app-provider/upateProviderAgentStatus', payload)

  const updateProviderLocation = payload => store.dispatch('app-provider/updateProviderLocation', payload)

  const updatePromocode = payload => store.dispatch('app-provider/updatePromocode', payload)

  const createPromocode = payload => store.dispatch('app-provider/createPromocode', payload)

  const getPromocodeInfo = payload => store.dispatch('app-provider/getPromocodeInfo', payload)

  const deletePromocode = payload => store.dispatch('app-provider/deletePromocode', payload)

  const paymentConfig = () => store.dispatch('app-provider/paymentConfig')

  const updatePaymentConfig = payload => store.dispatch('app-provider/updatePaymentConfig', payload)

  const createManualOrder = payload => store.dispatch('app-provider/createManualOrder', payload)

  const searchByPhoneNumber = payload => store.dispatch('app-provider/searchByPhoneNumber', payload)

  const searchByEmail = payload => store.dispatch('app-provider/searchByEmail', payload)

  const searchByEmailForAgent = payload => store.dispatch('app-provider/searchByEmailForAgent', payload)

  const searchByPhoneNumberForAgent = payload => store.dispatch('app-provider/searchByPhoneNumberForAgent', payload)

  const createUser = payload => store.dispatch('app-provider/createUser', payload)

  const createUserAddress = payload => store.dispatch('app-provider/createUserAddress', payload)

  const providerCartList = payload => store.dispatch('app-provider/providerCartList', payload)

  const providerAddCart = payload => store.dispatch('app-provider/providerAddCart', payload)

  const providerRemoveCart = payload => store.dispatch('app-provider/providerRemoveCart', payload)

  const providerAgentCartList = payload => store.dispatch('app-provider/providerAgentCartList', payload)

  const providerAgentAddCart = payload => store.dispatch('app-provider/providerAgentAddCart', payload)

  const providerAgentRemoveCart = payload => store.dispatch('app-provider/providerAgentRemoveCart', payload)

  const bookedTimeSlots = payload => store.dispatch('app-provider/bookedTimeSlots', payload)

  const providerServices = () => store.dispatch('app-provider/providerServices')

  const providerServicesForAgent = () => store.dispatch('app-provider/providerServicesForAgent')

  const acceptOrderRequest = payload => store.dispatch('app-provider/acceptOrderRequest', payload)

  const storeCancelOrder = payload => store.dispatch('app-provider/storeCancelOrder', payload)

  const agentOrders = () => store.dispatch('app-provider/agentOrders')

  const providerNotification = () => store.dispatch('app-provider/providerNotification')

  const getNotificationCount = () => store.dispatch('app-provider/getNotificationCount')

  const getDeliveryType = () => store.dispatch('app-provider/getDeliveryType')

  const incomingOrders = payload => store.dispatch('app-provider/incomingOrders', payload)

  const incomingServices = payload => store.dispatch('app-provider/incomingServices', payload)

  const getProviderOrders = payload => store.dispatch('app-provider/getProviderOrders', payload)

  const providerAssignAgentToOrder = payload => store.dispatch('app-provider/providerAssignAgentToOrder', payload)

  const getTicketSubCategory = payload => store.dispatch('app-provider/getTicketSubCategory', payload)

  const createTicket = payload => store.dispatch('app-provider/createTicket', payload)

  const getTicketInfo = payload => store.dispatch('app-provider/getTicketInfo', payload)

  const updateTicket = payload => store.dispatch('app-provider/updateTicket', payload)

  const addTicketComment = payload => store.dispatch('app-provider/addTicketComment', payload)

  const changeProviderStatus = payload => store.dispatch('app-provider/changeProviderStatus', payload)

  const currentOrders = payload => store.dispatch('app-provider/currentOrders', payload)

  const currentServices = payload => store.dispatch('app-provider/currentServices', payload)

  const agentUpdateOrder = payload => store.dispatch('app-provider/agentUpdateOrder', payload)

  const agentUpdateService = payload => store.dispatch('app-provider/agentUpdateService', payload)

  const providerNotifications = payload => store.dispatch('app-provider/providerNotifications', payload)

  const providerAutoComplete = payload => store.dispatch('app-provider/providerAutoComplete', payload)

  const ignoreOrder = payload => store.dispatch('app-provider/ignoreOrder', payload)

  const acceptOrder = payload => store.dispatch('app-provider/acceptOrder', payload)

  const acceptService = payload => store.dispatch('app-provider/acceptService', payload)

  const ignoreService = payload => store.dispatch('app-provider/ignoreService', payload)

  const cancelReasonsAgent = payload => store.dispatch('app-provider/cancelReasonsAgent', payload)

  const getServiceForEditById = payload => store.dispatch('app-provider/getServiceForEditById', payload)

  const updateServiceForEditById = payload => store.dispatch('app-provider/updateServiceForEditById', payload)

  const saveProviderStaticPage = payload => store.dispatch('app-provider/saveProviderStaticPage', payload)

  const getProviderStaticPage = payload => store.dispatch('app-provider/getProviderStaticPage', payload)

  const getAgentServiceForEditById = payload => store.dispatch('app-provider/getAgentServiceForEditById', payload)

  const updateAgentServiceForEditById = payload => store.dispatch('app-provider/updateAgentServiceForEditById', payload)

  const generateQr = payload => store.dispatch('app-provider/generateQr', payload)

  const providerSendOtp = payload => store.dispatch('app-provider/providerSendOtp', payload)

  const agentSendOtp = payload => store.dispatch('app-provider/agentSendOtp', payload)

  const agentUpdateOrderReview = payload => store.dispatch('app-provider/agentUpdateOrderReview', payload)

  const agentUpdateServiceReview = payload => store.dispatch('app-provider/agentUpdateServiceReview', payload)

  const providerPromocodes = payload => store.dispatch('app-provider/providerPromocodes', payload)

  const getProviderTiming = payload => store.dispatch('app-provider/getProviderTiming', payload)

  const saveProviderTiming = payload => store.dispatch('app-provider/saveProviderTiming', payload)

  return {
    saveProviderTiming,
    getProviderTiming,
    getProviderAgentProfile,
    providerPromocodes,
    agentUpdateServiceReview,
    agentUpdateOrderReview,
    agentSendOtp,
    providerSendOtp,
    searchByPhoneNumberForAgent,
    acceptService,
    ignoreService,
    generateQr,
    updateAgentServiceForEditById,
    getAgentServiceForEditById,
    getProviderStaticPage,
    saveProviderStaticPage,
    updateServiceForEditById,
    getServiceForEditById,
    cancelReasonsAgent,
    acceptOrder,
    ignoreOrder,
    incomingOrders,
    incomingServices,
    providerAutoComplete,
    providerNotifications,
    agentUpdateOrder,
    agentUpdateService,
    currentOrders,
    currentServices,
    changeProviderStatus,
    addTicketComment,
    updateTicket,
    getTicketInfo,
    createTicket,
    getTicketSubCategory,
    providerAssignAgentToOrder,
    getProviderOrders,
    getDeliveryType,
    getNotificationCount,
    providerNotification,
    storeCancelOrder,
    agentOrders,
    acceptOrderRequest,
    providerServices,
    providerServicesForAgent,
    bookedTimeSlots,
    providerCartList,
    providerAddCart,
    providerRemoveCart,
    providerAgentAddCart,
    providerAgentRemoveCart,
    providerAgentCartList,
    createManualOrder,
    searchByPhoneNumber,
    searchByEmail,
    searchByEmailForAgent,
    createUser,
    createUserAddress,
    updatePaymentConfig,
    paymentConfig,
    deletePromocode,
    getPromocodeInfo,
    createPromocode,
    updatePromocode,
    updateProviderLocation,
    providerTotalServices,
    providerRideType,
    providerShopType,
    providerVehicleEdit,
    providerProfile,
    providerVehicleAdd,
    updateProviderProfileInfo,
    providerChangePassword,
    providerBankDetails,
    editBankDetails,
    getProviderList,
    providerCards,
    getProviderAgentById,
    updateAgentsProfile,
    addAgentsProfile,
    deleteAgentsProfile,
    providerDisputeStatus,
    createServiceDispute,
    providerTripServiceHistory,
    providerAdminServices,
    providerListDocument,
    deleteProviderDocument,
    uploadProviderDocument,
    providerRequestList,
    providerRequestDetail,
    providerRequestHistoryList,
    providerAgentList,
    updateProviderRequest,
    providerVehicleAddService,
    providerVehicleEditService,
    addBankDetails,
    upateProviderAgentStatus,
  }
}
