<template>
  <div class="agent-order-info">
    <div v-if="!isProviderAgent" class="order-info-order">
      <div class="d-flex justify-content-start align-items-center">
        <div>
          <b-avatar ref="previewEl" :src="$helpers.imageHelper(orderDetail.user ? orderDetail.user.picture : '')"
            size="65px" rounded />
        </div>
        <div class="d-flex flex-column text-left ml-1">
          <div v-if="orderDetail.user" class="mb-0">
            <strong>{{ orderDetail.user.first_name }} {{ orderDetail.user.last_name }}</strong>
          </div>
          <div class="mb-0">
            <span class="text-muted">{{ $t('Invoice Id') }}:</span> {{ orderDetail.store_order_invoice_id }}
          </div>
          <div class="mb-0">
            <span class="text-muted">{{ $t('Order Date') }}:</span> {{ orderDetail.created_time }}
          </div>
        </div>
      </div>
      <div class="light-text pt-1">
        <p class="mb-0">
          <b>{{ $t('PICK UP') }}</b> ({{ orderDetail.pickup ? orderDetail.pickup.store_name : '' }})
        </p>
      </div>
      <div class="pickup-area-name">
        <p class="mb-0 clamp-2">
          {{ orderDetail.pickup ? orderDetail.pickup.store_location : '' }}
        </p>
      </div>
      <div class="light-text pt-1">
        <b>{{ $t('DROP OFF') }}</b>
      </div>
      <div class="dropoff-area-name">
        <p class="mb-0 clamp-2">
          {{ getDeliveryAddress(orderDetail) }}
        </p>
      </div>
      <div v-if="orderDetail.order_type === 'DELIVERY' && orderDetail.without_item == 1" class="light-text pt-1 mb-1">
        {{ $t('COD Amount') }}: {{ orderDetail.cod }} {{ orderDetail.currency }}
      </div>
      <div v-if="getBase.items" class="light-text pt-1 mb-1">
        <b>{{ $t('ORDER DETAILS') }}</b>
      </div>
      <table v-if="getBase && getBase.items" class="table border-top">
        <tr v-for="(item, index) of getBase.items" :key="index">
          <td class="px-0" colspan="3">
            <div class="d-grid grid-columns-template-wrapper">
              <p class="mb-0">
                {{ item.product.item_name }}
              </p>
              <p class="mb-0 text-right">
                {{ item.product.item_price }} {{ userCurrency }} x {{ item.quantity }}
              </p>
              <p class="mb-0 text-right">
                {{ userCurrency }}{{ item.total_item_price }}
              </p>
            </div>
            <p v-if="item.cartaddon && item.cartaddon.length > 0" class="mb-0">
              ({{ item.cartaddon.length }} {{ $t('addons') }})
            </p>
            <div v-if="item.cartaddon && item.cartaddon.length > 0" class="addons-wrapper">
              <div v-for="(addon, counter) of item.cartaddon" :key="counter"
                class="d-grid grid-columns-template-wrapper">
                <p class="mb-0">
                  {{ addon.addon_name }}
                </p>
                <p class="mb-0 text-right">
                  {{ addon.addon_price }} {{ userCurrency }} x {{ item.quantity }}
                </p>
                <p class="mb-0 text-right">
                  {{ userCurrency }}{{ addon.addon_price }}
                </p>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="text-right border-top-0 px-0">
            {{ $t('Total') }}: {{ userCurrency }} {{ getBase.total_amount }}
          </td>
        </tr>
      </table>
    </div>
    <div v-if="isProviderAgent" class="order-info-service">
      <div class="row d-flex justify-content-between">
        <div class="col-auto d-flex justify-content-start">
          <div>
            <b-avatar ref="previewEl" :src="$helpers.imageHelper(orderDetail.user ? orderDetail.user.picture : '')"
              size="50px" rounded />
          </div>
          <div class="ml-1 d-flex align-items-center flex-column text-left">
            <h4 v-if="orderDetail.user" class="mb-0 align-self-start">
              {{ orderDetail.user.first_name }} {{ orderDetail.user.last_name }}
            </h4>
            <h4 class="mb-0 align-self-start">
              {{ $t('Service No: ') }} {{ orderDetail.booking_id }}
            </h4>
          </div>
        </div>
        <div class="col-auto d-flex align-items-center">
          <img v-if="isProviderAgent && orderDetail && orderDetail.status !== 'COMPLETED'" class="cursor-pointer"
            :src="require('@/assets/images/app-icons/edit_grid.png')" @click="editService" style="width: 32px">
        </div>
      </div>
      <b-media>
        <template #aside>

        </template>
      </b-media>
      <div class="light-text pt-1">
        <p class="mb-0">
          <b>{{ $t('Service Location') }}</b>
        </p>
      </div>
      <div class="pickup-area-name">
        <p class="mb-0 clamp-2">
          {{ orderDetail.s_address }}
        </p>
      </div>
      <div class="light-text pt-1 mb-1">
        <b>{{ $t('Service Details') }}</b>
      </div>
      <table v-if="orderDetail && orderDetail.items" class="table border-top">
        <tr v-for="(item, index) of orderDetail.items" :key="index">
          <td class="px-0" colspan="3">
            <div class="d-grid grid-columns-template-wrapper">
              <p class="mb-0">
                {{ !isEmpty(item.provider_service.title) ? item.provider_service.title :
                  item.provider_service.main_service.service_name }}
              </p>
              <p class="mb-0 text-right">
                {{ item.service_price }} {{ userCurrency }} x {{ item.quantity }}
              </p>
              <p class="mb-0 text-right">
                {{ userCurrency }}{{ item.total_service_price }}
              </p>
            </div>
            <p v-if="item.cartaddon && item.cartaddon.length > 0" class="mb-0">
              ({{ item.cartaddon.length }} {{ $t('addons') }})
            </p>
            <div v-if="item.cartaddon && item.cartaddon.length > 0" class="addons-wrapper">
              <div v-for="(addon, counter) of item.cartaddon" :key="counter"
                class="d-grid grid-columns-template-wrapper">
                <p class="mb-0">
                  {{ addon.addon_name }}
                </p>
                <p class="mb-0 text-right">
                  {{ addon.addon_price }} {{ userCurrency }} x {{ item.quantity }}
                </p>
                <p class="mb-0 text-right">
                  {{ userCurrency }}{{ addon.addon_price }}
                </p>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="text-right border-top-0 px-0">
            {{ $t('Total') }}: {{ userCurrency }} {{ orderDetail.payment.payable }}
          </td>
        </tr>
      </table>
    </div>
    <b-modal id="chat-modal-popup" v-model="showChatMessage" :no-close-on-backdrop="true" hide-footer
      @hidden="showChatMessage = false">
      <chat :is-agent="true" :is-user-chat="true" :chat-type="chatType" :data-id="orderDetail.id"
        :user-name="userChatName" :customer-id="userCustomerId" :store-provider-id="storeProviderId"
        :provider-name="storeChatName" />
    </b-modal>
    <b-modal id="createServiceModal" v-model="showCreateNewService" class="provider-requests-order-modal"
      :no-close-on-backdrop="true" hide-footer :title="$t('Create Service')" size="xl"
      @hidden="serviceRequestModalClosed">
      <order-creation @create-order="orderCreated" @save-updated-service="saveUpdatedService" />
    </b-modal>
  </div>
</template>

<script>
import { BMedia, BAvatar, BModal } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import Chat from '../../shared/chat/Chat.vue'
import { useProviderUi } from '../useProvider'
import OrderCreation from '../shared/OrderCreation.vue'
import { isEmpty } from '@/utilities'

export default {
  components: { BMedia, BAvatar, Chat, BModal, OrderCreation },
  props: {
    orderDetail: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      showChatMessage: false,
      showCreateNewService: false,
    }
  },
  computed: {
    agentInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isProviderAgent() {
      return this.agentInfo.parent_type === 'PROVIDER'
    },
    userChatName() {
      if (!this.orderDetail) return ''
      if (!this.orderDetail.user) return ''
      return `${this.orderDetail.user.first_name} ${this.orderDetail.user.last_name}`
    },
    userCustomerId() {
      if (!this.orderDetail) return ''
      if (!this.orderDetail.user) return ''
      return this.orderDetail.user.id
    },
    storeProviderId() {
      if (!this.orderDetail) return ''
      if (this.orderDetail.store_id) return this.orderDetail.store_id
      if (this.orderDetail.provider_id) return this.orderDetail.provider_id
      return ''
    },
    chatType() {
      return this.orderDetail && this.orderDetail.store ? 'ORDER' : 'SERVICE'
    },
    getBase() {
      if (!this.orderDetail) return {}
      // eslint-disable-next-line no-nested-ternary
      return this.orderDetail.invoice ? this.orderDetail.invoice : this.orderDetail.order_invoice ? this.orderDetail.order_invoice : {}
    },
    userCurrency() {
      let cart = this.getBase
      if (!this.isProviderAgent) {
        if (cart.items && cart.items[0] && cart.items[0].store) {
          return cart.items[0].store.currency_symbol
        }
      }
      if (this.isProviderAgent) {
        cart = this.orderDetail
        if (cart.items && cart.items[0] && cart.items[0].provider) {
          return cart.items[0].provider.currency_symbol
        }
      }
      return ''
    },
    storeChatName() {
      const userInfo = window.SuperApp.getters.userInfo()
      if (userInfo) {
        return `${userInfo.first_name} ${userInfo.last_name}`
      }
      return ''
    },
  },
  methods: {
    isEmpty,
    orderCreated() {
      this.showCreateNewService = false
    },
    saveUpdatedService(service) {
      showLoader()
      const { updateAgentServiceForEditById } = useProviderUi()

      updateAgentServiceForEditById(service.id)
        .then(({ data }) => {
          if (data) {
            this.serviceRequestModalClosed()
            this.$emit('close-order-info')
            eventBus.$emit('refresh-agent-details-with-order')
            eventBus.$emit('provider-sync-app', {
              refreshDashboard: true,
              providerId: service.provider_id,
            })
          } else {
            showDangerNotification(this, data.message ? data.message : 'Failed to get service for edit')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    serviceRequestModalClosed() {
      this.showCreateNewService = false
      this.$store.commit('app-ecommerce/UPDATE_PROVIDER_SERVICE_REQUEST', null)
      this.$store.commit('app-ecommerce/UPDATE_EDIT_PROVIDER_SERVICE_REQUEST', null)
    },
    editService() {
      showLoader()
      const { getAgentServiceForEditById } = useProviderUi()

      getAgentServiceForEditById(this.orderDetail.id)
        .then(({ data }) => {
          if (data) {
            // eslint-disable-next-line no-param-reassign
            this.orderDetail.requestDetail = data.request
            this.$store.commit('app-ecommerce/UPDATE_PROVIDER_SERVICE_REQUEST', this.orderDetail)
            this.$store.commit('app-ecommerce/UPDATE_EDIT_PROVIDER_SERVICE_REQUEST', data.cart)
            this.showCreateNewService = true
          } else {
            showDangerNotification(this, data.message ? data.message : 'Failed to get service for edit')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getDeliveryAddress(payload) {
      if (!payload.delivery) return ''
      const address = []
      address.push(payload.delivery.flat_no)
      address.push(payload.delivery.street)
      address.push(payload.delivery.map_address)
      return address.filter(x => x).join(', ')
    },
    openCall() {
      if (this.orderDetail && this.orderDetail.user && this.orderDetail.user.mobile) {
        window.open(`tel:+${this.orderDetail.user.country_code ? this.orderDetail.user.country_code : ''}${this.orderDetail.user.mobile}`)
      }
    },
    openWhatsapp() {
      if (this.orderDetail && this.orderDetail.user && this.orderDetail.user.mobile) {
        window.open(`https://wa.me/${this.orderDetail.user.country_code ? this.orderDetail.user.country_code : ''}${this.orderDetail.user.mobile}/?text=Hello`)
      }
    },
  },
}
</script>

<style scoped>
.wrap-service-actions img {
  width: 50px;
}
</style>

<style scoped>
.grid-columns-template-wrapper {
  grid-template-columns: 40% 30% 30%;
  display: grid;
}
</style>
