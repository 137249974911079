/* eslint-disable eol-last */
import store from '@/store'

export const useLogin = () => {}

export const useLoginUi = () => {
  const userForgetPassword = payload => store.dispatch('app-auth/userForgetPassword', payload)

  const shopForgetPassword = payload => store.dispatch('app-auth/shopForgetPassword', payload)

  const providerForgetPassword = payload => store.dispatch('app-auth/providerForgetPassword', payload)

  const shopResetPassword = payload => store.dispatch('app-auth/shopResetPassword', payload)

  const providerResetPassword = payload => store.dispatch('app-auth/providerResetPassword', payload)

  const userSendOTP = payload => store.dispatch('app-auth/userSendOTP', payload)

  const userRegistration = payload => store.dispatch('app-auth/userRegistration', payload)

  const userVerifyOTP = payload => store.dispatch('app-auth/userVerifyOTP', payload)

  const userCountries = payload => store.dispatch('app-auth/getUserCountry', payload)

  const userResetPassword = payload => store.dispatch('app-auth/userResetPassword', payload)

  const shopTypeList = () => store.dispatch('app-auth/shopTypeList')

  const providerServiceList = () => store.dispatch('app-auth/providerServiceList')

  const shopRegistration = payload => store.dispatch('app-auth/shopRegistration', payload)

  const providerRegistration = payload => store.dispatch('app-auth/providerRegistration', payload)

  return {
    providerRegistration,
    shopRegistration,
    providerServiceList,
    shopTypeList,
    shopForgetPassword,
    providerForgetPassword,
    shopResetPassword,
    providerResetPassword,
    userForgetPassword,
    userSendOTP,
    userRegistration,
    userVerifyOTP,
    userCountries,
    userResetPassword,
  }
}
