<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-input-group
            :label="$t('Address')"
            label-for="address"
            class="mb-1"
          >
            <GmapAutocomplete
              class="form-control"
              :class="{ 'is-invalid': !editAddress.map_address && isSubmitted }"
              :value="editAddress.map_address"
              @place_changed="setPlace"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="selectCurrentLocation"
              >
                <feather-icon icon="MapPinIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <GmapMap
            :center="center"
            :zoom="12"
            style="width: 100%; height: 400px"
          >
            <GmapMarker
              v-if="markers"
              :position="markers"
              @click="center = markers"
            />
          </GmapMap>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            :label="$t('Flat/Villa/Office No')"
            label-for="flatNo"
          >
            <b-form-input
              id="flatNo"
              v-model="editAddress.flat_no"
              :class="{ 'is-invalid': !editAddress.flat_no && isSubmitted }"
              type="text"
              @change="setAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            :label="$t('Building/Villa Name')"
            label-for="building"
          >
            <b-form-input
              id="building"
              v-model="editAddress.building"
              type="text"
              @change="setAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            :label="$t('Street')"
            label-for="addressStreet"
          >
            <b-form-input
              id="addressStreet"
              v-model="editAddress.street"
              :class="{ 'is-invalid': !editAddress.street && isSubmitted }"
              type="text"
              @change="setAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label-for="zipCode"
            :label="$t('Zip/Pincode')"
          >
            <b-form-input
              id="zipCode"
              v-model="editAddress.zipcode"
              :class="{ 'is-invalid': !editAddress.zipcode && isSubmitted }"
              type="text"
              @change="setAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            :label="$t('Type')"
            label-for="addressType"
          >
            <b-form-select
              v-model="editAddress.address_type"
              :class="{ 'is-invalid': !editAddress.address_type && isSubmitted }"
              :options="addressTypeInfo"
              @change="setAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-right"
        >
          <button
            class="btn btn-primary"
            @click="saveDetails"
          >
            {{ $t('Save') }}
          </button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BFormGroup, BFormInput, BRow, BCol, BFormSelect, BInputGroup, BInputGroupAppend, BButton } from 'bootstrap-vue'
import { showDangerNotification } from '@/@core/comp-functions/ui/app'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      isSubmitted: false,
      editAddress: {
        map_address: '',
        flat_no: '',
        street: '',
        address_type: '',
        id: 0,
        building: '',
        zipcode: '',
      },
      markers: null,
      center: { lat: 45.508, lng: -73.587 },
      addressTypeInfo: [
        {
          text: 'Home',
          value: 'Home',
        },
        {
          text: 'Work',
          value: 'Work',
        },
        {
          text: 'Other',
          value: 'Other',
        },
      ],
    }
  },
  methods: {
    saveDetails() {
      if (this.editAddress.map_address && this.editAddress.flat_no && this.editAddress.street && this.editAddress.address_type && this.editAddress.zipcode) {
        this.isSubmitted = false
        this.$emit('save-details')
      } else {
        this.isSubmitted = true
        showDangerNotification(this, 'Please enter required fields')
      }
    },
    selectCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.setAddressName(position.coords.latitude, position.coords.longitude)
            this.markers = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
            this.center = this.markers
            this.editAddress.latitude = position.coords.latitude
            this.editAddress.longitude = position.coords.longitude
          },
          () => {
            showDangerNotification(this, 'Unable to get your location')
          },
        )
      } else {
        showDangerNotification(this, 'Sorry, your browser does not support geolocation services')
      }
    },
    setAddressName(latitude, longitude) {
      window.SuperApp.helper.getFormattedAddress(`${parseFloat(latitude)},${parseFloat(longitude)}`).then(res => {
        this.editAddress.map_address = window.SuperApp.helper.parsedAddress(res)
      })
    },
    setPlace(place) {
      const marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.markers = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.center = this.markers
      if (place.address_components) {
        const zipCode = place.address_components.find(x => Array.isArray(x.types) && x.types.find(y => y === 'postal_code'))
        if (zipCode) {
          this.editAddress.zipcode = zipCode.short_name
        }
      }
      
      this.editAddress.map_address = place.formatted_address
      this.editAddress.latitude = marker.lat
      this.editAddress.longitude = marker.lng
      this.$emit('address-selected', place)
    },
    setAddress() {
      this.$emit('set-address', this.editAddress)
    },
  },
}
</script>
